import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const HomeBgWrapper = styled(motion.div)`
  /* position: absolute; */
  align-self: flex-end;
  position: absolute;
  /* bottom: -10px; */
  right: 0;
  z-index: -1;
  width: 800px;
  /* bottom: 0; */
  /* right */

  @media (max-width: 800px) {
    display: none;
  }
`
const homeBgVariants = {
  initial: { opacity: 0 },
  final: {
    opacity: 1,
    transition: { delay: 1.7, duration: 1.5, ease: 'easeOut' },
  },
}

const HomeBg = () => {
  return (
    <HomeBgWrapper variants={homeBgVariants} initial="initial" animate="final">
      <motion.svg
        width="100%"
        height="100%"
        // width="826"
        // height="487"
        viewBox="0 0 826 487"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="headerimage 1" clipPath="url(#clip0)">
          <g id="Layer 2">
            <g id="Layer 3">
              <g id="Lamp">
                <path
                  id="Body"
                  d="M711.428 99.9924L729.259 81.014C729.972 80.2549 730.819 79.6527 731.752 79.2419C732.684 78.8311 733.683 78.6196 734.692 78.6196C735.701 78.6196 736.7 78.8311 737.633 79.2419C738.565 79.6527 739.412 80.2549 740.125 81.014V81.014L787.599 131.534C790.478 134.6 792.096 138.756 792.098 143.089V477.725"
                  stroke="#CBCBCB"
                  strokeWidth="8"
                  strokeMiterlimit="10"
                />
                <path
                  id="Lamp Base"
                  d="M817.071 476.713H766.17C764.579 476.713 763.289 478.085 763.289 479.778V480.797C763.289 482.49 764.579 483.862 766.17 483.862H817.071C818.663 483.862 819.953 482.49 819.953 480.797V479.778C819.953 478.085 818.663 476.713 817.071 476.713Z"
                  fill="#AFB9C5"
                />
                <g id="Lamp Top">
                  <path
                    id="Lamp 2"
                    d="M722.153 88.8538C725.096 91.9842 727.431 95.7008 729.024 99.7911C730.617 103.881 731.437 108.265 731.437 112.693C731.437 117.12 730.617 121.504 729.024 125.595C727.431 129.685 725.096 133.401 722.153 136.532L703.137 156.756L658.318 109.082L677.335 88.8538C683.278 82.5328 691.339 78.9819 699.744 78.9819C708.149 78.9819 716.209 82.5328 722.153 88.8538V88.8538Z"
                    fill="#AFB9C5"
                  />
                  <path
                    id="Lamp 3"
                    d="M704.176 155.969C706.628 153.39 698.526 140.686 686.079 127.595C673.632 114.504 661.554 105.982 659.102 108.561C656.65 111.14 664.752 123.844 677.199 136.935C689.646 150.026 701.724 158.548 704.176 155.969Z"
                    fill="#F2F2F2"
                  />
                </g>
              </g>
              <g id="Big Leaf Plant">
                <g id="Big Ass Leaf">
                  <path
                    id="leaf"
                    d="M45.3866 242.063C48.1627 241.607 51.0025 241.924 53.629 242.983C59.0691 245.435 60.6146 252.281 60.5424 257.834C60.4634 263.814 59.2683 270.346 63.5441 275.165C65.5085 277.382 67.9538 279.073 69.8839 281.331C71.9217 283.736 73.5698 286.484 74.7607 289.463C76.9587 294.903 78.0371 301.303 76.028 306.999C74.2559 312.026 69.8015 316.559 70.7631 322.276C71.7659 328.242 77.4155 332.794 80.9494 337.255C84.9471 342.281 88.6321 348.7 89.2297 355.291C89.8067 361.607 87.2275 367.993 83.2608 372.768C75.3377 382.306 63.4273 386.982 51.4173 386.953C46.3173 386.953 40.5441 386.697 36.1447 383.782C34.9322 382.957 33.9389 381.815 33.2598 380.465C32.5222 379.29 31.5747 378.281 30.4711 377.495C28.0858 375.54 26.2476 372.929 25.1547 369.944C20.7965 358.469 24.2789 344.272 30.3784 334.084C31.8242 331.673 33.0091 329.334 32.3051 326.47C31.5701 323.46 29.7705 320.757 28.1838 318.17C26.4865 315.703 24.9489 313.115 23.5818 310.425C22.3591 307.587 21.8684 304.454 22.1599 301.347C22.7307 295.416 24.9027 289.788 28.4139 285.141C31.7006 280.535 35.5643 276.133 35.5711 270.101C35.5711 263.708 31.5632 258.024 33.277 251.525C33.9213 248.688 35.4882 246.185 37.7005 244.459C39.895 242.907 42.8005 242.399 45.3866 242.063Z"
                    fill="#DDE3E9"
                  />
                  <path
                    id="middle thing"
                    d="M43.5905 252.281C43.6215 252.09 43.6879 251.908 43.7858 251.744C43.8836 251.581 44.011 251.44 44.1606 251.329C44.3102 251.219 44.4789 251.141 44.6569 251.101C44.835 251.06 45.0188 251.058 45.1978 251.093C45.25 251.103 45.3017 251.117 45.3523 251.134C46.3449 251.43 46.3243 252.412 46.3002 253.293C46.2525 255.971 46.5541 258.644 47.1966 261.235C48.4639 266.685 50.8061 271.701 52.6641 276.944C54.5221 282.186 55.391 287.567 54.3023 293.098C53.272 298.315 51.6647 303.393 50.6138 308.617C49.6865 313.22 48.7043 318.075 50.1124 322.667C51.4861 327.084 54.4671 330.683 56.6377 334.679C60.9409 342.905 63.2497 352.143 63.3553 361.556C63.5794 365.949 63.2618 370.355 62.4108 374.66C61.8613 377.137 61.1195 380.483 59.1276 382.189C58.2072 382.975 56.6342 383.249 55.8615 382.087C55.1334 380.991 55.7722 379.296 56.0023 378.131C56.94 373.081 57.2632 367.924 56.9639 362.787C56.8346 357.812 56.0768 352.879 54.711 348.119C53.2934 343.779 51.317 339.669 48.8382 335.907C46.3517 331.983 43.7691 328.052 42.7422 323.402C41.7119 318.678 42.5499 313.874 43.6798 309.238C44.8784 304.336 46.4479 299.579 48.0724 294.812C49.7084 290.402 50.1475 285.592 49.3396 280.929C47.6053 271.303 42.9861 262.302 43.5905 252.281Z"
                    fill="#C5CFD6"
                  />
                </g>
                <g id="Big Ass Leaf-2">
                  <path
                    id="leaf 2"
                    d="M118.023 253.72C121.506 254.715 124.682 256.667 127.234 259.379C132.385 265.294 130.812 274.233 127.962 280.765C124.895 287.794 120.252 294.863 122.872 302.922C124.077 306.63 126.1 309.987 127.244 313.724C128.442 317.702 129.01 321.864 128.923 326.039C128.8 333.693 126.887 341.858 121.691 347.491C117.116 352.456 109.636 355.36 107.926 362.645C106.143 370.251 110.505 378.766 112.442 385.974C114.633 394.128 115.759 403.762 113.194 411.886C110.735 419.679 104.529 425.802 97.5058 429.254C83.473 436.151 67.1769 435.099 53.1063 428.41C47.1305 425.571 40.4816 422.086 36.769 416.205C35.7565 414.554 35.1583 412.655 35.0312 410.688C34.7501 408.89 34.1373 407.17 33.2282 405.625C31.4047 401.98 30.5486 397.875 30.752 393.752C31.3427 377.773 42.4804 362.911 54.6998 354.235C57.595 352.178 60.1364 350.089 60.7443 346.297C61.3762 342.336 60.6104 338.135 60.0265 334.201C59.4427 330.266 58.5498 326.438 58.4776 322.51C58.4526 318.469 59.4324 314.493 61.3179 310.984C64.7763 304.193 70.3262 299.042 76.7038 295.276C82.8513 291.652 89.5655 288.579 92.574 281.452C95.7611 273.893 93.8756 264.95 99.0993 258.21C101.27 255.419 104.309 252.942 107.805 252.303C111.164 251.678 114.822 252.686 118.023 253.72Z"
                    fill="#DDE3E9"
                  />
                  <path
                    id="middle thing-2"
                    d="M111.068 266.211C111.327 265.796 111.731 265.508 112.19 265.409C112.649 265.311 113.126 265.41 113.516 265.685C113.578 265.732 113.64 265.78 113.695 265.831C114.725 266.733 114.203 267.891 113.74 268.918C112.365 272.066 111.402 275.399 110.879 278.825C109.68 285.986 109.948 293.23 109.543 300.467C109.137 307.704 107.499 314.569 103.505 320.512C99.7273 326.116 95.3519 331.242 91.5501 336.835C88.2015 341.77 84.6607 346.969 84.0494 353.194C83.4587 359.182 85.193 365.118 85.7666 371.051C86.9171 383.333 84.7363 395.297 80.3884 406.622C78.4902 411.942 75.9504 416.977 72.8327 421.601C70.9713 424.227 68.4574 427.775 65.2771 428.688C63.8141 429.108 61.8428 428.553 61.4993 426.741C61.1868 425.038 62.7666 423.387 63.6115 422.134C67.1944 416.674 70.1096 410.75 72.2867 404.507C74.5872 398.541 76.1302 392.275 76.875 385.876C77.3532 379.951 77.0631 373.984 76.013 368.143C75.041 362.112 73.9523 356.011 75.0445 349.932C76.1503 343.758 79.5092 338.541 83.1118 333.689C86.9274 328.557 91.107 323.793 95.3519 319.055C99.4494 314.727 102.333 309.271 103.68 303.298C106.4 290.972 105.435 277.722 111.068 266.211Z"
                    fill="#C5CFD6"
                  />
                </g>
                <g id="Big Ass Leaf-3">
                  <path
                    id="leaf 3"
                    d="M12.7793 299.093C18.4391 299.093 23.4258 302.286 26.8602 306.816C30.875 312.117 31.6683 318.594 33.3134 324.91C34.0137 328.153 35.6376 331.088 37.9635 333.313C40.189 335.256 43.012 335.647 45.6737 336.601C48.3062 337.544 50.6974 339.124 52.6596 341.215C54.6218 343.307 56.1016 345.854 56.983 348.656C59.0436 355.459 56.8594 362.319 57.2372 369.213C57.5806 375.544 63.4534 379.493 68.1756 382.529C72.8292 385.521 77.0225 388.323 78.4272 394.154C79.9143 400.335 80.4123 407.239 78.3516 413.348C74.9173 423.518 64.8889 427.997 55.1903 428.691C46.3914 429.319 35.3259 428.326 28.7182 421.538C24.7652 417.472 23.3331 411.788 23.5117 406.129C23.6795 402.607 24.2198 399.117 25.1224 395.724C25.853 393.34 26.4019 390.896 26.764 388.418C27.3788 381.795 21.6125 380.52 16.7254 378.704C10.7768 376.523 5.87726 371.934 3.09097 365.932C0.26792 360.266 -0.916936 353.91 0.776206 347.666C1.64893 344.483 3.20832 341.561 5.33017 339.132C6.26776 338.036 7.58655 337.149 8.4211 336.013C8.738 335.468 8.9337 334.854 8.99322 334.217C9.05275 333.58 8.97452 332.937 8.76454 332.337C7.52473 326.598 3.61299 321.849 2.20834 316.121C0.951358 310.951 1.69662 304.511 6.14755 301.175C8.10888 299.783 10.4215 299.057 12.7793 299.093Z"
                    fill="#C5CFD6"
                  />
                  <path
                    id="middle thing-3"
                    d="M20.4938 314.053C26.4936 320.855 28.1249 330.073 28.6023 339.019C28.8702 344.032 28.6023 349.318 29.9761 354.18C31.1609 358.341 33.9359 361.523 36.8002 364.53C42.563 370.583 48.9887 376.735 50.675 385.496C51.6641 390.636 50.9978 395.947 52.3647 401.033C53.6777 405.726 55.7373 410.144 58.4538 414.097C60.5969 417.238 63.389 419.642 65.7313 422.576C66.4456 423.467 67.4691 424.768 67.1737 426.046C66.8303 427.508 65.0581 427.994 63.8012 427.763C60.8716 427.219 58.4058 424.245 56.4928 422.083C53.7022 418.972 51.3095 415.484 49.3768 411.707C47.1503 407.507 45.5929 402.943 44.7679 398.202C44.002 393.273 44.3832 388.133 42.6351 383.399C39.6885 375.431 32.5484 370.733 27.3797 364.53C20.9505 356.811 21.9053 347.184 22.5647 337.671C22.9081 332.444 23.2859 326.536 20.8029 321.765C19.855 319.939 17.7394 318.243 17.3341 316.172C16.9598 314.272 18.9964 312.351 20.4938 314.053Z"
                    fill="#AFB9C5"
                  />
                </g>
                <path
                  id="Base"
                  d="M26.8926 425.769C26.8926 464.65 32.8581 485.02 64.3478 485.02C95.8375 485.02 101.8 464.65 101.8 425.769H26.8926Z"
                  fill="#89C5CC"
                />
              </g>
              <motion.path
                id="Vector"
                d="M155.922 125.079C161.379 125.046 166.675 124.297 170.415 118.605C177.751 107.426 188.961 97.391 201.51 108.742C208.753 115.299 203.306 128.783 209.543 135.271C215.055 141.007 222.065 140.061 224.884 149.581C229.033 163.587 215.955 175.859 211.761 187.973C208.241 198.136 216.161 202.008 219.722 209.929C222.47 216.033 221.055 222.576 218.064 228.414C212.792 238.705 201.105 246.994 203.811 260.321C206.071 271.445 208.173 281.36 198.948 289.788C192.622 295.567 180.887 300.835 181.776 311.532C182.092 315.317 184.554 318.542 185.231 322.276C186.299 328.18 182.703 333.93 178.527 337.985C169.024 347.209 160.668 355.908 153.559 367.51C150.516 372.471 138.338 383.665 143.205 390.445C134.361 378.123 125.002 366.173 116.973 353.219C110.362 342.566 100.395 326.981 107.178 313.859C109.29 309.778 113.212 306.735 114.287 302.22C116.231 294.066 108.7 286.511 105.897 279.57C101.501 268.676 104.729 256.029 112.47 247.853C116.135 243.984 120.112 239.728 120.651 234.266C121.238 228.304 117.722 222.744 116.128 217.242C114.689 212.109 113.71 206.842 113.205 201.511C112.828 197.793 112.68 193.99 113.501 190.351C115.067 183.432 120.922 179.585 125.257 174.679C135.577 162.988 130.154 149.252 134.663 135.669C138.726 123.479 147.522 125.13 155.922 125.079Z"
                fill="#1D7E8E"
              />
              <g id="Group">
                <path
                  id="Vector_2"
                  d="M142.124 404.346C138.291 357.625 141.811 310.535 149.923 264.523C157.664 220.625 167.823 176.582 183.236 134.972C188.295 121.206 194.069 107.75 200.532 94.666"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_3"
                  d="M235.199 157.315C213.551 165.448 192.988 176.538 174.026 190.308C169.218 193.797 166.549 194.863 162.545 199.353"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_4"
                  d="M152.293 249.406C173.123 235.109 196.491 225.514 220.95 221.214"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_5"
                  d="M144.086 309.677C163.517 291.811 186.822 279.369 211.901 273.473"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_6"
                  d="M163.618 197.811C162.095 173.026 156.345 148.742 146.648 126.146"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_7"
                  d="M152.56 247.367C142.701 224.969 128.625 204.961 111.203 188.583"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_8"
                  d="M142.801 311.39C141.439 304.478 139.07 297.836 135.781 291.702C131.959 284.582 126.958 278.266 121.865 272.114C118.202 267.679 114.472 263.306 110.676 258.995"
                  stroke="#00A09D"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
              </g>
              <path
                id="Vector_9"
                d="M88.9162 210.236C84.953 211.014 81.0172 211.251 77.5622 207.678C70.7862 200.653 61.3554 195.024 53.7243 205.121C49.3214 210.941 55.0293 219.92 51.3511 225.546C48.0988 230.518 42.8888 230.862 42.0783 238.183C40.8935 248.953 51.9727 255.93 56.592 264.099C60.4694 270.952 55.2182 274.931 53.6762 281.2C52.481 286.033 54.3631 290.566 57.2857 294.351C62.4373 301.04 72.0054 305.333 71.7753 315.397C71.583 323.8 71.3494 331.3 79.1386 336.056C84.479 339.319 93.6763 341.412 94.4215 349.303C94.6825 352.094 93.3157 354.783 93.3122 357.603C93.3122 362.045 96.6607 365.684 100.219 368.011C108.314 373.301 115.498 378.383 122.171 385.751C125.025 388.9 135.315 395.228 132.667 400.861C137.475 390.632 142.705 380.578 146.851 369.995C150.261 361.293 155.461 348.525 148.832 340.005C146.772 337.353 143.53 335.724 142.163 332.608C139.693 326.978 144.172 320.388 145.302 314.941C147.07 306.396 143.087 297.694 136.403 292.905C133.244 290.636 129.806 288.155 128.707 284.254C127.501 280.016 129.328 275.464 129.771 271.237C130.146 267.302 130.17 263.337 129.844 259.397C129.634 256.643 129.246 253.863 128.174 251.36C126.141 246.571 121.392 244.645 117.61 241.723C108.602 234.782 110.742 223.997 105.71 214.81C101.194 206.55 95.026 209.034 88.9162 210.236Z"
                fill="#00A09D"
              />
              <g id="Group_2">
                <path
                  id="Vector_10"
                  d="M135.274 410.776C131.973 376.322 123.295 342.672 111.418 310.498C100.085 279.811 86.9932 249.343 70.4053 221.429C64.9454 212.188 59.0062 203.278 52.6152 194.739"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_11"
                  d="M35.6152 245.288C52.3761 248.001 68.7346 253.019 84.2802 260.215C88.2194 262.042 90.2972 262.407 93.7865 265.085"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_12"
                  d="M107.737 299.882C90.7659 292.578 72.5655 289.059 54.2637 289.544"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_13"
                  d="M121.532 342.394C105.113 332.295 86.5906 326.701 67.6328 326.116"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_14"
                  d="M92.8076 264.128C90.6889 245.925 91.7014 227.465 95.7955 209.647"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_15"
                  d="M107.279 298.443C111.518 280.744 119.125 264.159 129.63 249.712"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
                <path
                  id="Vector_16"
                  d="M122.688 343.447C122.776 338.233 123.63 333.066 125.219 328.133C127.063 322.404 129.869 317.089 132.774 311.876C134.856 308.12 136.992 304.399 139.183 300.711"
                  stroke="#1D7E8E"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                />
              </g>
              <path
                id="Vector_17"
                d="M173.803 398.844H92.8242L107.032 485.046H159.595L173.803 398.844Z"
                fill="#9D786A"
              />
              <path
                id="Vector_18"
                d="M183.72 374.645H82.9141V398.84H183.72V374.645Z"
                fill="#94624F"
              />
              <path
                id="Vector_19"
                opacity="0.1"
                d="M94.7097 410.275H171.918L173.803 398.844H92.8242L94.7097 410.275Z"
                fill="#231F20"
              />
              <path
                id="Vector_20"
                d="M742.841 208.358H739.137V341.394H742.841V208.358Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_21"
                d="M771.982 331.179H709.994V341.394H771.982V331.179Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_22"
                d="M718.815 320.969H763.16C764.025 320.969 764.855 321.334 765.467 321.985C766.079 322.636 766.423 323.519 766.423 324.439V331.183H715.553V324.439C715.553 323.519 715.896 322.636 716.508 321.985C717.12 321.334 717.95 320.969 718.815 320.969V320.969Z"
                fill="#CBCBCB"
              />
              <path
                id="Vector_23"
                d="M780.928 241.774H700.375L720.586 157.699H760.713L780.928 241.774Z"
                fill="#393939"
              />
              <path
                id="Vector_24"
                d="M753.789 241.774H728.186L733.237 157.699H748.733L753.789 241.774Z"
                fill="#515047"
              />
              <path
                id="Vector_25"
                d="M826 341.394H690.572V353.219H826V341.394Z"
                fill="#9D786A"
              />
              <path
                id="Vector_26"
                d="M826.004 435.994H690.576V447.819H826.004V435.994Z"
                fill="#9D786A"
              />
              <path
                id="Vector_27"
                d="M826 353.219H690.572V390.307H826V353.219Z"
                fill="#9D786A"
              />
              <path
                id="Vector_28"
                d="M702.029 353.219H690.572V484.374H702.029V353.219Z"
                fill="#94624F"
              />
              <path
                id="Vector_29"
                d="M826.001 353.219H814.545V484.374H826.001V353.219Z"
                fill="#94624F"
              />
              <path
                id="Vector_30"
                d="M809.831 358.235H706.748V384.754H809.831V358.235Z"
                fill="#94624F"
              />
              <path
                id="Vector_31"
                d="M807.676 260.967C808.67 259.128 808.318 256.929 806.891 256.056C805.463 255.184 803.501 255.968 802.507 257.807C801.513 259.647 801.864 261.846 803.292 262.718C804.719 263.591 806.682 262.807 807.676 260.967Z"
                fill="#00A09D"
              />
              <path
                id="Vector_32"
                d="M804.128 264.332C803.674 264.269 803.251 264.053 802.922 263.715C802.601 263.324 802.411 262.831 802.382 262.312C802.352 261.794 802.486 261.28 802.761 260.851L803.592 259.55C802.943 259.896 802.36 260.368 801.875 260.942C799.749 263.174 801.394 267.412 804.571 266.334C805.158 266.133 806.092 265.366 805.35 264.778C804.978 264.538 804.561 264.386 804.128 264.332Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_33"
                d="M805.318 274.933C807.068 270.623 805.593 265.8 802.025 264.161C798.456 262.523 794.146 264.689 792.396 268.999C790.647 273.31 792.121 278.132 795.689 279.771C799.258 281.41 803.569 279.244 805.318 274.933Z"
                fill="#00A09D"
              />
              <path
                id="Vector_34"
                d="M801.665 278.825C801.371 279.111 801.018 279.321 800.635 279.439C800.171 279.626 799.693 279.772 799.206 279.877C797.74 280.144 795.576 279.775 794.305 278.953C791.389 277.071 791.242 272.012 792.447 269.049C794.195 264.738 798.505 262.572 802.064 264.212C801.583 263.989 800.164 264.724 799.708 264.943C799.041 265.266 798.434 265.715 797.918 266.269C797.039 267.285 796.465 268.557 796.27 269.922C795.967 271.698 796.373 272.921 797.369 274.394C798.163 275.571 799.196 276.544 800.391 277.239C800.81 277.484 801.737 277.667 801.827 278.244C801.844 278.348 801.838 278.454 801.81 278.555C801.782 278.655 801.732 278.748 801.665 278.825V278.825Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_35"
                d="M784.837 282.514C788.186 282.514 790.902 279.626 790.902 276.063C790.902 272.5 788.186 269.611 784.837 269.611C781.487 269.611 778.771 272.5 778.771 276.063C778.771 279.626 781.487 282.514 784.837 282.514Z"
                fill="#00A09D"
              />
              <path
                id="Vector_36"
                d="M782.94 267.964C783.16 268.52 783.221 269.132 783.115 269.725C783.008 270.318 782.739 270.863 782.342 271.293C781.944 271.723 781.436 272.019 780.88 272.142C780.325 272.265 779.748 272.21 779.222 271.985C778.696 271.759 778.245 271.373 777.925 270.875C777.605 270.377 777.43 269.79 777.424 269.187C777.418 268.583 777.579 267.992 777.888 267.486C778.198 266.98 778.641 266.584 779.162 266.346C779.51 266.186 779.884 266.102 780.262 266.097C780.641 266.092 781.017 266.167 781.368 266.318C781.719 266.468 782.039 266.691 782.308 266.974C782.578 267.256 782.793 267.593 782.94 267.964V267.964Z"
                fill="#00A09D"
              />
              <path
                id="Vector_37"
                d="M794.607 301.413C800.003 301.413 804.377 295.521 804.377 288.254C804.377 280.986 800.003 275.095 794.607 275.095C789.21 275.095 784.836 280.986 784.836 288.254C784.836 295.521 789.21 301.413 794.607 301.413Z"
                fill="#00A09D"
              />
              <path
                id="Vector_38"
                d="M794.607 301.416C789.211 301.416 784.836 295.524 784.836 288.265C784.836 281.006 789.211 275.113 794.607 275.113C787.996 283.001 788.501 296.729 794.607 301.416Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_39"
                d="M788.086 280.078C788.096 280.361 788.044 280.642 787.935 280.9C787.813 281.296 787.627 281.667 787.385 281.996C786.313 283.333 783.923 282.679 782.649 282.087C781.155 281.397 779.809 280.14 779.304 278.434C778.679 276.348 778.761 273.648 780.132 271.858C780.316 271.605 780.547 271.393 780.81 271.238C781.073 271.082 781.364 270.985 781.663 270.952C781.241 270.978 781.79 273.414 781.852 273.725C782.05 274.916 782.534 276.032 783.257 276.965C784.061 277.926 785.002 278.142 786.121 278.427C786.952 278.664 787.972 279.029 788.086 280.078Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_40"
                d="M780.831 271.128C780.028 272.103 779.348 272.223 778.403 271.365C778.056 271.033 777.795 270.611 777.647 270.139C777.499 269.668 777.468 269.164 777.558 268.676C777.672 267.946 778.245 266.85 778.898 266.514C779.399 266.251 780.114 266.682 780.57 266.371C779.306 267.23 778.726 269.067 780.083 270.233C780.313 270.43 780.584 270.569 780.821 270.751C780.868 270.781 780.904 270.826 780.924 270.879C780.952 270.967 780.89 271.069 780.831 271.128Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_41"
                d="M807.345 341.394H779.174L776.045 308.431H810.474L807.345 341.394Z"
                fill="#9D786A"
              />
              <path
                id="Vector_42"
                d="M811.535 296.01H774.982V308.431H811.535V296.01Z"
                fill="#94624F"
              />
              <path
                id="Vector_43"
                d="M325.274 417.268L543.799 417.268V322.792H325.274V417.268Z"
                fill="#66A5AF"
              />
              <path
                id="Vector_44"
                d="M229.005 369.743H409.848C424.675 369.743 436.691 355.623 436.691 338.208V202.633C436.691 185.218 424.671 171.099 409.848 171.099C409.848 171.099 359.837 177.916 320.582 177.916C281.327 177.916 229.005 171.099 229.005 171.099C214.178 171.099 202.158 185.218 202.158 202.633V338.208C202.158 355.623 214.178 369.743 229.005 369.743Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_45"
                d="M259.126 419.357H377.039C409.748 419.357 436.13 387.552 435.653 348.696C435.653 348.696 436.089 237.664 435.701 211.857C435.653 348.696 200.512 348.696 202.164 212.836C201.7 246.724 200.498 348.696 200.498 348.696C200.035 387.552 226.417 419.357 259.126 419.357Z"
                fill="#66A5AF"
              />
              <path
                id="Vector_46"
                d="M409.113 171.099H228.908C228.908 171.099 268.932 188.999 320.146 188.999C371.359 188.999 409.113 171.099 409.113 171.099Z"
                fill="#8ED2CC"
              />
              <path
                id="Vector_47"
                d="M673.333 484.783C676.767 483.687 678.629 479.555 677.578 475.471L663.318 412.763L644.045 418.974L664.943 479.541C666.2 483.574 669.916 485.879 673.333 484.783Z"
                fill="#94624F"
              />
              <path
                id="Vector_48"
                d="M201.337 484.783C197.902 483.687 196.041 479.555 197.092 475.471L211.351 412.763L230.621 418.974L209.727 479.541C208.466 483.574 204.754 485.879 201.337 484.783Z"
                fill="#94624F"
              />
              <path
                id="Vector_49"
                d="M463.043 367.653H644.873C659.778 367.653 671.863 353.533 671.863 336.115V200.562C671.863 183.143 659.778 169.023 644.873 169.023C644.873 169.023 594.587 175.84 555.119 175.84C515.651 175.84 463.043 169.023 463.043 169.023C448.135 169.023 436.053 183.143 436.053 200.562V336.133C436.053 353.533 448.135 367.653 463.043 367.653Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_50"
                d="M494.165 417.267H612.716C645.604 417.267 672.131 385.462 671.65 346.603C671.65 346.603 672.09 235.571 671.698 209.764C671.65 346.603 435.235 346.603 436.911 210.743C436.444 244.634 435.235 346.603 435.235 346.603C434.754 385.462 461.278 417.267 494.165 417.267Z"
                fill="#66A5AF"
              />
              <path
                id="Vector_51"
                d="M543.803 407.024C496.532 406.98 257.033 407.122 209.745 407.122C208.526 407.122 188.713 385.141 183.74 374.243C178.345 362.403 175.559 351.575 172.441 339.903C167.255 320.435 161.856 292.656 172.75 274.211C177.5 266.152 187.116 260.632 195.565 263.763C207.403 268.146 211.232 281.883 212.97 293.796C217.132 322.459 228.929 352.419 257.579 360.573C273.326 365.059 290.308 364.044 306.508 364.054C308.607 364.054 543.792 364.343 543.789 363.324C543.796 377.89 543.8 392.457 543.803 407.024Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_52"
                d="M538.09 407.024C585.36 406.98 617.984 407.123 665.251 407.123C666.473 407.123 686.286 385.141 691.259 374.244C696.651 362.404 699.44 351.575 702.558 339.903C707.751 320.454 713.143 292.675 702.266 274.229C697.52 266.152 687.904 260.632 679.452 263.763C667.61 268.147 663.784 281.883 662.046 293.796C657.863 322.459 646.066 352.419 617.42 360.584C601.674 365.07 584.687 364.055 568.491 364.066C566.389 364.066 538.1 364.354 538.1 363.335L538.09 407.024Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_53"
                d="M436.508 322.788C436.508 334.083 447.951 365.636 543.616 365.636C639.28 365.636 650.724 334.083 650.724 322.788C650.724 311.492 602.769 302.33 543.616 302.33C484.462 302.33 436.508 311.496 436.508 322.788Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_54"
                d="M436.508 322.788C452.392 312.069 634.352 311.919 650.724 322.788C650.724 311.496 602.769 302.33 543.616 302.33C484.462 302.33 436.508 311.496 436.508 322.788Z"
                fill="#8ED2CC"
              />
              <path
                id="Vector_55"
                d="M436.691 322.788C436.691 334.083 448.135 365.636 543.803 365.636C639.47 365.636 650.91 334.083 650.91 322.788C638.364 365.636 447.949 365.636 436.691 322.788Z"
                fill="#66A5AF"
              />
              <path
                id="Vector_56"
                d="M221.645 322.788C221.645 334.083 233.088 365.636 328.756 365.636C424.423 365.636 435.863 334.083 435.863 322.788C435.863 311.492 387.909 302.33 328.756 302.33C269.602 302.33 221.645 311.496 221.645 322.788Z"
                fill="#66BBC4"
              />
              <path
                id="Vector_57"
                d="M221.645 322.788C237.532 312.069 419.492 311.919 435.863 322.788C435.863 311.496 387.909 302.33 328.756 302.33C269.602 302.33 221.645 311.496 221.645 322.788Z"
                fill="#8ED2CC"
              />
              <path
                id="Vector_58"
                d="M221.834 322.788C221.834 334.083 233.277 365.636 328.945 365.636C424.613 365.636 436.053 334.083 436.053 322.788C423.504 365.636 233.088 365.636 221.834 322.788Z"
                fill="#66A5AF"
              />
              <path
                id="Vector_59"
                d="M644.976 169.005H463.785C463.785 169.005 504.026 186.906 555.517 186.906C607.009 186.906 644.976 169.005 644.976 169.005Z"
                fill="#8ED2CC"
              />
              <path
                id="Vector_60"
                d="M209.927 419.357L665.252 419.357V407.122L209.927 407.122V419.357Z"
                fill="#94624F"
              />
              <path
                id="Vector_61"
                d="M205.991 261.625C206.568 264.588 214.745 297.113 215.243 301.446C215.741 305.778 214.848 319.401 214.9 316.975C214.951 314.55 214.659 322.112 219.141 321.425C221.377 321.082 269.307 310.977 286.616 308.306C303.977 305.625 321.564 305.892 323.268 305.588C326.671 304.986 327.901 303.031 329.106 297.449C330.312 291.867 322.924 269.14 321.808 261.801C320.692 254.462 323.323 249.614 322.574 246.008C321.582 241.237 317.295 239.688 313.604 240.163C311.491 240.44 289.79 244.989 272.858 247.203C256.153 249.394 213.375 253.727 211.627 253.957C208.124 254.421 205.417 258.666 205.991 261.625Z"
                fill="#00A09D"
              />
              <path
                id="Vector_62"
                d="M206.297 261.589C206.874 264.548 215.047 297.073 215.549 301.409C216.05 305.745 215.154 319.364 215.205 316.939C215.257 314.513 214.968 322.079 219.447 321.388C221.686 321.045 269.613 310.94 286.925 308.27C304.238 305.599 321.873 305.855 323.577 305.555C326.98 304.949 328.21 302.998 329.415 297.416C329.965 294.881 328.728 288.805 327.131 282.244C290.576 301.497 215.858 323.31 216.171 253.749C213.928 253.822 212.468 253.859 211.939 253.928C208.419 254.381 205.72 258.626 206.297 261.589Z"
                fill="#1D7E8E"
              />
              <path
                id="Vector_63"
                opacity="0.2"
                d="M322.583 246.005C321.591 241.233 317.304 239.684 313.612 240.159C311.5 240.437 289.799 244.985 272.867 247.199C263.855 248.379 225.387 253.366 220.002 253.611C241.195 280.604 323.332 249.61 322.583 246.005Z"
                fill="white"
              />
              <motion.path
                initial={{ x: 0 }}
                animate={{
                  x: '-10px',
                  transition: { yoyo: Infinity, duration: 3 },
                }}
                id="Vector_64"
                d="M537.909 290.735C527.544 290.212 517.21 291.984 506.969 293.752C503.734 294.307 500.309 296.218 502.744 300.328C504.084 302.586 506.866 303.25 509.349 303.55C520.05 304.854 531.044 303.411 541.8 303.429"
                fill="#0B2D4E"
              />
              <path
                id="Vector_65"
                d="M614.155 275.453C615.728 285.682 611.219 300.452 600.723 303.437C596.496 304.642 591.403 304.215 587.034 304.189C581.718 304.153 576.402 304.111 571.088 304.065L539.76 303.828C532.891 303.773 525.562 303.587 519.816 299.553C512.992 294.76 509.592 285.898 514.133 278.27C518.031 271.72 525.322 267.705 532.276 266.108C543.929 263.438 556.032 266.978 567.613 264.756C569.508 264.401 571.372 263.873 573.183 263.178C583.641 259.16 594.03 253.589 604.704 260.172C609.828 263.328 613.197 269.243 614.155 275.453Z"
                fill="#061F33"
              />
              <path
                id="Vector_66"
                d="M527.66 253.435C525.919 251.473 524.848 251.09 523.793 253.881C522.577 257.099 521.757 260.603 521.794 264.091C517.406 265.09 513.299 267.171 509.805 270.167C507.119 268.136 503.929 266.857 500.731 266.09C497.984 265.429 497.665 266.587 498.193 269.224C498.843 272.483 499.89 275.628 500.9 278.777C501.532 280.75 502.105 282.741 502.751 284.703C503.194 286.685 504.034 288.542 505.213 290.146C508.263 294.165 514.328 296.521 519.033 297.182C524.356 297.913 529.398 296.485 532.984 292.181C536.198 288.32 538.393 283.048 539.231 277.996C539.719 275.07 539.482 272.06 538.544 269.261C537.476 266.137 535.367 263.861 533.53 261.249C531.692 258.637 529.817 255.861 527.66 253.435Z"
                fill="#0B2D4E"
              />
              <motion.path
                initial={{
                  transformbox: 'fill-box',
                  origin: '100%',
                  skewY: -8,
                }}
                animate={{
                  skewY: 8,
                  origin: '100%',
                  transition: { duration: 2, yoyo: Infinity },
                }}
                id="cat-tail"
                d="M606.303 267.325C616.325 277.473 615.274 289.171 609.738 299.517C603.597 310.992 593.486 318.762 582.208 323.818C568.889 329.788 553.644 332.842 539.381 329.261C536.513 328.531 533.378 327.044 532.536 324.041C530.036 315.16 543.063 315.317 547.706 314.908C557.254 314.042 567.368 313.94 576.654 310.637C586.841 307.013 594.098 299.279 599.191 289.387"
                fill="#0B2D4E"
              />
              <path
                id="Vector_67"
                d="M613.565 283.68C613.534 278.87 612.003 274.208 609.214 270.423C602.122 260.924 588.319 260.639 578.816 265.779C567.915 271.679 566.188 287.3 570.457 298.293C570.653 298.797 566.638 298.658 566.301 298.677C563.42 298.892 557.454 298.311 556.304 302.063C555.535 304.58 557.114 307.985 559.491 308.8C560.498 309.082 561.541 309.197 562.582 309.139C570.048 309.139 577.514 309.139 584.981 309.169C590.819 309.169 595.243 308.029 598.746 302.724C600.569 299.963 601.95 296.879 603.897 294.212"
                fill="#0B2D4E"
              />
              <path
                id="Vector_68"
                d="M525.041 290.234C526.31 289.712 526.995 288.348 526.573 287.187C526.15 286.026 524.78 285.508 523.511 286.03C522.243 286.552 521.557 287.917 521.98 289.078C522.402 290.239 523.773 290.756 525.041 290.234Z"
                fill="#091A27"
              />
              <path
                id="Vector_69"
                d="M537.091 265.907C537.463 266.587 537.872 267.243 538.314 267.873C538.532 268.207 538.72 268.563 538.874 268.936C539.018 269.316 539.133 269.708 539.217 270.108C539.389 270.894 539.496 271.687 539.599 272.465C539.702 273.243 539.784 274.05 539.822 274.85C539.893 276.461 539.805 278.074 539.561 279.665C539.07 282.863 537.969 285.92 536.326 288.649C535.511 290.008 534.558 291.269 533.485 292.408C532.421 293.55 531.231 294.552 529.941 295.392C529.288 295.81 528.588 296.137 527.856 296.364C527.129 296.59 526.363 296.634 525.617 296.492C527.115 296.474 528.433 295.721 529.567 294.797C530.14 294.33 530.687 293.828 531.205 293.292C531.463 293.021 531.727 292.762 531.971 292.477C532.215 292.192 532.465 291.922 532.702 291.63C533.647 290.477 534.489 289.235 535.22 287.918C535.958 286.61 536.584 285.235 537.091 283.808C538.129 280.952 538.691 277.926 538.754 274.865C538.774 274.094 538.754 273.345 538.706 272.552C538.658 271.76 538.644 270.996 538.558 270.236C538.524 269.866 538.461 269.499 538.369 269.14C538.265 268.777 538.134 268.423 537.978 268.081C537.627 267.383 537.331 266.656 537.091 265.907V265.907Z"
                fill="#73B8D4"
              />
              <path
                id="Vector_70"
                d="M416.995 318.386C414.145 319.836 411.217 321.106 408.227 322.189C394.977 327.129 381.241 330.447 367.286 332.078C352.72 333.78 337.28 333.448 324.38 326.054C302.981 313.782 305.615 292.177 305.618 270.159C305.618 268.526 306.228 266.959 307.314 265.804C308.4 264.649 309.873 264 311.408 264H407.316C412.468 264 418.203 263.236 423.314 264C426.205 264.431 428.644 264.665 429.942 268.146C430.849 270.59 430.285 276.019 430.698 278.803C432.216 289.167 434.407 302.333 427.112 310.703C424.269 313.976 420.765 316.413 416.995 318.386Z"
                fill="#0B2D4E"
              />
              <path
                id="Vector_71"
                d="M320.101 248.189C319.678 247.177 319.252 246.143 318.806 245.076C314.131 233.953 310.718 222.276 308.64 210.294C307.892 205.98 307.129 201.26 304.031 198.363C299.742 194.344 291.386 194.874 286.076 195.63C283.593 195.995 280.997 196.894 279.5 199.028C277.017 202.564 277.264 210.546 277.168 214.806C276.574 240.703 295.82 262.115 311.491 279.592C314.51 282.957 317.786 286.358 321.966 287.72C326.145 289.083 331.455 287.666 333.299 283.443C334.638 280.341 333.818 276.702 332.753 273.48C330.929 267.938 328.178 262.864 325.046 258.053C322.92 254.801 321.529 251.623 320.101 248.189Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_72"
                d="M414.715 248.189C415.137 247.177 415.563 246.143 416.009 245.076C420.684 233.953 424.097 222.276 426.175 210.294C426.924 205.98 427.683 201.26 430.784 198.363C435.074 194.344 443.426 194.874 448.739 195.63C451.222 195.995 453.818 196.894 455.316 199.028C457.799 202.564 457.552 210.546 457.648 214.806C458.242 240.703 438.996 262.115 423.304 279.592C420.289 282.957 417.012 286.358 412.833 287.72C408.653 289.083 403.343 287.666 401.499 283.443C400.156 280.341 400.98 276.702 402.042 273.48C403.869 267.938 406.62 262.864 409.748 258.053C411.895 254.801 413.286 251.623 414.715 248.189Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_73"
                d="M291.088 164.724C289.594 168.742 288.161 172.761 286.75 176.78C281.918 190.585 270.43 214.002 283.388 226.726C284.854 228.188 288.388 228.089 290.208 227.705C295.418 226.609 298.042 221.444 300.597 217.049C308.259 203.857 313.813 189.405 321.204 176.031C326.534 166.379 329.233 154.868 331.792 144.112C333.478 136.996 335.659 126.814 330.02 120.922C328.86 119.728 327.467 118.821 325.94 118.265C324.414 117.709 322.791 117.517 321.186 117.703C319.263 117.941 317.433 118.723 315.691 119.632C314.19 120.379 312.781 121.322 311.498 122.438C308.366 125.284 306.851 129.397 304.973 133.178C302.775 137.628 300.532 142.041 298.478 146.571C295.8 152.519 293.375 158.59 291.088 164.724Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_74"
                d="M296.916 142.936C304.076 128.966 313.4 116.403 327.739 111.278C331.86 109.816 336.929 109.451 340.013 112.695C341.943 114.726 342.644 117.809 342.479 120.685C342.242 124.871 340.463 128.839 339.557 132.89C337.136 143.71 333.466 154.17 328.628 164.03C327.598 166.153 319.946 189.164 320.561 189.734C315.519 185.076 308.338 179.271 302.307 176.217C300.704 175.406 286.09 167.406 285.668 168.418C289.058 160.307 292.609 151.368 296.916 142.936Z"
                fill="#393939"
              />
              <path
                id="Vector_75"
                d="M445.384 164.724C446.878 168.742 448.31 172.761 449.721 176.779C454.553 190.585 466.041 214.002 453.083 226.726C451.617 228.188 448.083 228.089 446.263 227.705C441.053 226.609 438.429 221.444 435.874 217.049C428.212 203.857 422.658 189.405 415.268 176.031C409.937 166.379 407.238 154.867 404.679 144.112C402.986 136.996 400.809 126.807 406.452 120.911C407.611 119.718 409.003 118.811 410.529 118.255C412.055 117.699 413.678 117.506 415.281 117.692C417.205 117.93 419.035 118.711 420.776 119.621C422.28 120.369 423.691 121.312 424.977 122.43C428.109 125.276 429.623 129.39 431.502 133.171C433.7 137.621 435.943 142.034 437.996 146.564C440.672 152.518 443.096 158.59 445.384 164.724Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_76"
                d="M439.489 142.936C432.329 128.966 423.004 116.403 408.666 111.278C404.545 109.816 399.475 109.451 396.391 112.695C394.461 114.726 393.761 117.809 393.926 120.685C394.163 124.871 395.942 128.839 396.848 132.89C399.268 143.71 402.939 154.17 407.776 164.03C408.807 166.153 416.458 189.164 415.844 189.734C420.885 185.076 428.066 179.271 434.097 176.217C435.701 175.406 450.314 167.406 450.737 168.418C447.347 160.307 443.81 151.368 439.489 142.936Z"
                fill="#393939"
              />
              <path
                id="Vector_77"
                d="M419.124 250.206C419.028 249.789 418.935 249.373 418.846 248.956C415.459 233.364 414.965 217.272 415.387 201.336C415.803 185.712 419.076 170.335 419.508 154.703C420 136.674 417.046 111.062 395.835 108.979C387.064 108.121 378.244 107.112 369.39 107.003V106.981H368.446H367.505V107.003C358.668 107.112 349.849 108.121 341.077 108.979C319.867 111.062 316.913 136.674 317.404 154.703C317.827 170.335 321.1 185.712 321.526 201.336C321.948 217.272 321.453 233.364 318.067 248.956C317.974 249.373 317.885 249.789 317.789 250.206C317.123 253.128 316.048 256.105 316.553 259.072C317.641 265.483 332.152 268.592 337.042 269.973C346.16 272.53 355.588 273.692 364.967 274.43L367.495 274.624V274.762L368.456 274.693L369.421 274.762V274.624L371.949 274.43C381.339 273.699 390.756 272.545 399.874 269.973C404.765 268.592 419.275 265.483 420.364 259.072C420.865 256.091 419.79 253.135 419.124 250.206Z"
                fill="#E0250C"
              />
              <path
                id="Vector_78"
                d="M354.809 124.034C367.904 133.229 393.397 126.098 386.175 107.858C385.296 105.644 384.231 103.503 383.647 101.194C382.679 97.3619 382.775 93.4164 382.875 89.5038C382.991 84.9519 383.026 80.389 383.259 75.8481C378.472 74.8946 374.587 74.4197 369.501 74.617C365.294 74.7777 361.09 74.3649 356.887 74.617C356.554 74.5984 356.224 74.6903 355.942 74.88C355.84 74.9926 355.759 75.1259 355.705 75.2721C355.652 75.4183 355.626 75.5743 355.63 75.7312C355.063 81.8759 355.63 88.0974 355.176 94.253C354.929 97.698 354.49 101.636 353.009 104.829C350.732 109.746 347.353 114.309 350.605 119.643C351.708 121.399 353.139 122.894 354.809 124.034Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_79"
                d="M383.455 100.369C382.7 96.8068 382.899 93.0623 382.992 89.4675C383.109 84.9156 383.143 80.3564 383.376 75.8118C378.589 74.8583 374.705 74.3834 369.618 74.5806C365.411 74.745 361.207 74.3286 357.004 74.5624C356.671 74.5438 356.341 74.6357 356.059 74.8254C355.957 74.938 355.876 75.0714 355.822 75.2175C355.769 75.3637 355.743 75.5197 355.747 75.6766C355.18 81.8213 355.747 88.0428 355.293 94.1984C355.167 95.9679 354.931 97.7265 354.586 99.4627C355.249 100.007 355.918 100.529 356.609 101.008C361.805 104.621 368.804 105.823 375.021 104.164C378.005 103.388 380.853 102.106 383.455 100.369V100.369Z"
                fill="#E5785C"
              />
              <path
                id="Vector_80"
                d="M298 191.86L296.805 194.874H439.242L437.813 191.487L298 191.86Z"
                fill="#B8D1CE"
              />
              <path
                id="Vector_81"
                d="M429.413 290.618H306.58L297.781 193.343C297.74 192.933 297.781 192.519 297.901 192.127C298.02 191.735 298.216 191.374 298.475 191.067C298.734 190.76 299.05 190.515 299.404 190.346C299.758 190.177 300.141 190.09 300.528 190.088H435.499C435.887 190.09 436.27 190.177 436.623 190.346C436.977 190.515 437.294 190.76 437.552 191.067C437.811 191.374 438.007 191.735 438.126 192.127C438.246 192.519 438.287 192.933 438.247 193.343L429.413 290.618Z"
                fill="#B8D1CE"
              />
              <path
                id="Vector_82"
                d="M430.379 293.354H305.618L296.689 196.079C296.651 195.667 296.695 195.251 296.817 194.858C296.939 194.465 297.137 194.104 297.399 193.797C297.66 193.49 297.979 193.245 298.334 193.077C298.69 192.909 299.075 192.821 299.464 192.821H436.55C436.94 192.821 437.325 192.908 437.681 193.076C438.037 193.243 438.356 193.489 438.618 193.796C438.88 194.102 439.078 194.464 439.201 194.858C439.323 195.251 439.367 195.667 439.329 196.079L430.379 293.354Z"
                fill="white"
              />
              <path
                id="Vector_83"
                d="M430.302 293.369H305.617V299.272H430.302V293.369Z"
                fill="#B8D1CE"
              />
              <path
                id="Vector_84"
                d="M376.648 243.104C376.648 244.924 376.141 246.703 375.19 248.216C374.24 249.73 372.889 250.909 371.308 251.606C369.727 252.302 367.987 252.485 366.309 252.129C364.631 251.774 363.09 250.898 361.88 249.611C360.67 248.324 359.846 246.684 359.512 244.899C359.178 243.114 359.349 241.264 360.004 239.582C360.659 237.901 361.768 236.463 363.191 235.452C364.613 234.441 366.286 233.901 367.997 233.901C369.133 233.901 370.258 234.139 371.308 234.601C372.358 235.063 373.312 235.741 374.115 236.596C374.919 237.45 375.556 238.465 375.991 239.582C376.425 240.698 376.649 241.895 376.648 243.104V243.104Z"
                fill="#B8D1CE"
              />
              <path
                id="Vector_85"
                d="M361.204 314.674C356.678 316.402 352.137 318.071 347.594 319.609C342.947 321.188 316.289 325.747 321.575 336.674C322.605 338.818 325.13 339.519 327.362 339.961C340.186 342.482 353.243 341.346 366.201 339.687"
                fill="#F8A17A"
              />
              <path
                id="Vector_86"
                d="M341.899 341.825C336.383 343.513 330.466 344.356 325.301 345.617C322.969 346.183 320.637 346.753 318.277 347.151C314.531 347.79 310.722 348.006 306.944 348.218L298.681 348.685C297.18 348.77 295.611 348.839 294.244 348.174C292.612 347.378 291.565 345.683 290.603 344.068C288.697 340.868 286.437 337.857 284.847 334.453C283.817 332.224 282.721 330.043 283.862 327.599C284.472 326.424 285.272 325.372 286.224 324.49C287.37 323.277 288.65 322.218 290.037 321.334C294.646 318.539 299.536 319.767 304.427 320.822C309.606 321.944 314.781 323.223 320.07 323.555C325.764 323.92 331.438 323.16 337.091 322.536"
                fill="#D5EFF7"
              />
              <path
                id="Vector_87"
                d="M322.578 349.321L325.198 349.142C327.259 349.007 329.818 348.755 331.408 347.199C331.664 346.965 331.869 346.674 332.009 346.348C332.318 345.526 331.892 344.554 331.226 344.024C330.523 343.542 329.72 343.251 328.887 343.177C329.838 343.33 331.291 339.549 331.449 338.851C331.921 336.862 332.086 334.803 331.937 332.758C331.772 330.905 331.329 329.13 330.178 327.705C329.591 326.975 327.547 324.75 326.479 325.075C327.403 324.794 327.853 323.566 327.592 322.583C327.331 321.601 326.466 320.903 325.552 320.581C324.622 320.31 323.656 320.208 322.695 320.278C317.639 320.355 312.508 320.728 307.515 319.683C303.204 318.756 298.792 318.484 294.409 318.875C291.026 319.184 287.716 320.097 284.618 321.575C283.773 321.975 282.967 322.465 282.214 323.036C272.082 330.745 279.679 344.294 288.54 348.737C295.089 352.025 301.803 351.137 308.748 350.468C313.367 350.041 317.948 349.654 322.578 349.321Z"
                fill="#393939"
              />
              <path
                id="Vector_88"
                d="M391.677 80.5613C387.707 85.6575 382.146 89.0148 376.634 92.0945C375.285 92.8507 373.907 93.6033 372.42 93.9722C367.859 95.1047 363.254 92.4744 359.449 89.5665C354.187 85.5435 349.437 80.8135 345.316 75.4942C341.178 70.1423 338.863 64.2971 336.164 58.0136C333.464 51.73 332.849 45.3113 333.416 38.3885C334.007 31.2793 330.27 24.9263 331.956 17.7368C333.055 13.1322 335.619 9.07478 339.213 6.25473C341.167 4.73133 343.438 3.59518 345.845 3.33215C351.234 2.7111 355.805 6.62005 361.135 6.87212C366.465 7.12419 371.675 5.04551 376.837 3.81072C381.346 2.71476 386.285 2.11928 390.756 3.74862C395.736 5.57523 398.137 9.59377 400.888 14.3028C403.244 18.3213 406.785 21.3206 409.618 24.8971C413.396 29.6463 414.608 35.7326 413.396 41.9467C412.337 47.3156 410.498 52.4761 407.945 57.2427C405.761 61.2942 403.381 65.5648 400.805 69.4043C398.844 72.3269 396.684 74.486 394.407 77.0359C393.428 78.1099 392.601 79.3703 391.677 80.5613Z"
                fill="#0B2D4E"
              />
              <path
                id="Vector_89"
                d="M396.553 56.172C396.467 50.3268 395.66 44.8141 394.204 40.233C387.933 20.469 364.634 15.8185 350.766 30.1976C335.311 46.2352 341.63 84.1191 358.201 97.2744C360.436 99.02 363.012 100.208 365.737 100.749C368.463 101.29 371.268 101.172 373.944 100.402C390.062 95.6195 396.828 74.3943 396.553 56.172Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_90"
                d="M400.361 40.1858C393.304 20.4328 367.014 15.8115 351.343 30.2088C347.678 33.5771 343.787 38.8743 342.07 43.7257C340.249 48.9206 340.727 54.6635 341.232 60.1652C341.623 55.6097 343.636 51.5144 346.438 48.1096C347.839 46.4442 349.37 44.9069 351.013 43.5138C351.604 43.0061 356.044 38.7099 355.618 38.1217C357.202 40.3136 358.768 42.5056 361.206 43.6015C364.249 44.9751 367.419 45.7679 370.637 46.5241C376.328 47.8393 380.782 49.5417 384.91 54.0717C388.729 58.2583 391.49 63.4641 393.328 68.9366C393.908 70.6646 394.897 73.3205 397.023 72.5497C397.524 72.367 401.903 68.8964 401.948 68.6553C402.705 64.5272 403.056 60.3265 402.995 56.1211C402.903 50.2723 401.996 44.745 400.361 40.1858Z"
                fill="#E5785C"
              />
              <path
                id="Vector_91"
                d="M387.779 52.241C389.198 54.7033 390.063 57.4724 390.922 60.216C392.272 64.5523 393.326 69.2139 395.078 73.3676C400.992 69.5719 404.89 60.6215 407.582 54.148C409.986 48.4015 410.639 43.8386 408.612 37.8144C407.307 33.9201 404.797 30.4605 401.823 27.4941C393.237 18.9273 380.341 14.5653 367.912 15.4384C363.547 15.7417 359.985 17.3783 355.891 18.6423C352.114 19.804 349.249 20.5639 346.481 23.8591C344.022 26.7817 342.446 30.4349 341.206 34.1429C339.19 40.1817 338.046 46.4031 338.489 52.8219C338.774 56.9208 338.802 60.9831 342.611 63.2153C342.542 60.7798 342.474 58.337 342.408 55.8869C342.341 54.72 342.382 53.549 342.532 52.3908C342.725 51.3678 343.005 50.3652 343.366 49.3951C344.396 46.3995 345.32 44.003 347.25 41.5371C349.778 38.3113 353.109 35.3923 353.817 31.2642C359.796 37.4454 366.868 41.3179 374.712 44.1966C379.692 46.0232 384.757 47.0717 387.714 52.1351L387.779 52.241Z"
                fill="#0B2D4E"
              />
              <path
                id="Vector_92"
                d="M396.669 56.6941C403.782 52.4564 406.083 62.9704 404.912 68.2274C404.463 70.4147 403.607 72.4843 402.394 74.3136C400.715 76.7284 395.869 80.7616 393.057 78.1678C391.772 76.9878 391.621 74.9676 391.566 73.1629C391.401 68.3333 391.861 59.5656 396.669 56.6941Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_93"
                d="M345.247 56.6941C338.135 52.4564 335.834 62.9704 337.005 68.2274C337.453 70.4147 338.309 72.4843 339.522 74.3136C341.202 76.7284 346.048 80.7616 348.86 78.1678C350.145 76.9878 350.296 74.9676 350.351 73.1629C350.512 68.3333 350.069 59.5656 345.247 56.6941Z"
                fill="#F8A17A"
              />
              <path
                id="Vector_94"
                d="M335.106 39.5681C335.045 36.4848 333.118 33.8618 331.648 31.2022C329.385 26.9982 328.46 22.1289 329.01 17.32C329.319 14.5251 330.228 11.843 331.665 9.48049C333.102 7.11795 335.031 5.13807 337.301 3.69348C338.97 2.63011 340.823 1.93607 342.75 1.65295C344.676 1.36984 346.637 1.50348 348.514 2.04588C352.021 3.10897 354.871 5.96213 358.275 6.95581C365.899 9.17332 373.729 2.57194 380.966 0.986446C388.15 -0.595399 395.83 1.97647 399.422 9.65554C400.837 12.6877 401.462 16.1948 403.313 18.9128C406.404 23.4538 412.352 24.9552 414.811 29.9565C418.83 38.1251 410.845 50.0711 406.782 56.4204"
                stroke="#0B2D4E"
                strokeWidth="3"
                strokeMiterlimit="10"
              />
              <path
                id="Vector_95"
                d="M353.129 15.446C359.05 15.8515 364.806 14.4888 370.596 13.2358C373.516 12.6812 376.471 12.3613 379.436 12.2786C380.92 12.2299 382.408 12.2385 383.901 12.3042C385.398 12.3337 386.891 12.4913 388.365 12.7755C391.402 13.3975 394.115 15.1918 395.983 17.8133C396.907 19.2088 397.171 20.9295 397.267 22.5004C397.363 24.0712 397.332 25.6385 397.635 27.1144C397.937 28.5903 398.627 29.9054 399.51 31.1329C400.392 32.3604 401.368 33.5842 402.044 35.0309C402.677 36.4845 403.027 38.0587 403.075 39.6595C403.128 41.2301 403.074 42.8026 402.913 44.3649C402.966 42.803 402.933 41.2392 402.814 39.6815C402.701 38.1397 402.298 36.6378 401.629 35.2647C400.942 33.9203 399.956 32.7696 399.032 31.5348C398.109 30.3 397.233 28.8898 396.91 27.2788C396.532 25.6933 396.543 24.0822 396.433 22.5515C396.323 21.0208 396.062 19.523 395.255 18.3357C394.85 17.7284 394.372 17.1806 393.833 16.7064C393.299 16.2085 392.724 15.7622 392.116 15.3729C390.887 14.6111 389.548 14.0739 388.152 13.7838C386.729 13.4954 385.287 13.3292 383.839 13.2869C382.38 13.2114 380.919 13.1871 379.453 13.2139C376.527 13.2856 373.608 13.5516 370.713 14.0103C367.831 14.4962 364.912 15.1391 361.972 15.4716C359.033 15.819 356.066 15.8104 353.129 15.446V15.446Z"
                fill="#73B8D4"
              />
              <path
                id="Vector_96"
                d="M355.736 342.672C361.784 341.898 379.9 338.964 385.869 338.288C415.813 334.854 445.449 329.645 473.188 316.519C479.583 313.479 486.335 308.482 489.92 301.979C499.334 284.842 483.357 267.237 466.597 271.234C438.607 277.912 415.707 290.311 388.672 300.511C384.159 302.209 376.04 305.859 371.465 307.66C367.54 309.205 354.359 313.392 350.402 314.901"
                fill="#0B2D4E"
              />
              <path
                id="Vector_97"
                d="M366.975 405.333C368.438 410.042 369.952 414.729 371.559 419.343C373.204 424.063 385.857 448.455 374.849 450.687C372.689 451.126 370.68 449.496 369.032 447.947C359.56 439.03 352.571 427.738 346.021 416.215"
                fill="#F8A17A"
              />
              <path
                id="Vector_98"
                d="M359.002 437.247C361.087 442.727 364.013 448.05 366.214 453.003C367.2 455.236 368.186 457.471 369.305 459.63C371.088 463.057 373.203 466.272 375.315 469.494L379.917 476.486C380.755 477.758 381.635 479.077 382.936 479.774C384.482 480.626 386.326 480.461 388.06 480.278C391.495 479.913 395.015 479.953 398.408 479.212C400.63 478.729 402.845 478.309 403.903 475.924C404.381 474.726 404.66 473.449 404.727 472.15C404.916 470.495 404.916 468.822 404.727 467.167C403.982 461.746 400.177 458.505 396.485 455.163C392.587 451.623 388.586 448.181 385.175 444.079C381.518 439.666 378.65 434.602 375.738 429.634"
                fill="#D5EFF7"
              />
              <path
                id="Vector_99"
                d="M365.16 457.431C364.686 456.686 364.208 455.94 363.728 455.195C362.594 453.442 361.244 451.209 361.413 448.985C361.427 448.637 361.513 448.296 361.663 447.987C362.069 447.257 363.037 447.012 363.803 447.235C364.568 447.519 365.257 447.998 365.809 448.63C365.122 447.947 366.98 444.513 367.385 443.961C368.535 442.397 369.913 441.04 371.469 439.943C372.897 438.971 374.439 438.27 376.15 438.354C377.025 438.394 379.845 438.719 380.247 439.789C379.903 438.869 380.501 437.755 381.373 437.397C381.819 437.245 382.292 437.207 382.754 437.287C383.216 437.367 383.654 437.562 384.032 437.857C384.781 438.452 385.432 439.176 385.958 439.998C388.922 444.155 391.721 448.546 395.454 451.984C398.7 454.926 401.538 458.343 403.882 462.133C405.681 465.067 407.003 468.303 407.79 471.704C408.009 472.635 408.138 473.587 408.175 474.546C408.69 487.391 394.417 489.313 385.931 484.775C379.656 481.414 376.284 475.427 372.612 469.384C370.15 465.347 367.691 461.402 365.16 457.431Z"
                fill="#393939"
              />
              <path
                id="Vector_100"
                d="M350.132 426.503C347.072 421.125 338.355 404.646 335.281 399.385C319.854 372.994 305.89 345.792 298.743 315.401C297.105 308.387 296.652 299.923 299.19 293.117C305.876 275.216 328.069 277.693 335.213 293.705C347.14 320.446 351.914 346.468 360.744 374.54C362.217 379.223 364.446 388.002 365.895 392.806C367.132 396.919 371.998 410.137 373.279 414.254"
                fill="#0B2D4E"
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="826" height="487" fill="white" />
          </clipPath>
        </defs>
      </motion.svg>
    </HomeBgWrapper>
  )
}

export default HomeBg
