import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const HomeBgCloudsWrapper = styled(motion.div)`
  position: absolute;
  right: 0;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  transition: background 0.5 ease-out;

  @media (max-width: 800px) {
    display: none;
  }
`
const homeBgCloudsVariants = {
  initial: { opacity: 0 },
  final: {
    opacity: 1,
    transition: {
      delay: 0.8,
      duration: 1,
      ease: 'easeOut',
      // staggerChildren: 2,
      when: 'beforeChildren',
    },
  },
}

const HomeBgClouds = () => {
  return (
    <HomeBgCloudsWrapper
      variants={homeBgCloudsVariants}
      initial="initial"
      animate="final"
    >
      <motion.svg
        width="876"
        height="900"
        viewBox="0 0 876 900"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.g id="clouds">
          <motion.g id="Layer 1">
            <motion.g id="Group 15">
              <motion.path
                id="cloud-background"
                d="M952.402 524.893C954.071 484.866 955.724 444.837 957.36 404.806C960.357 331.867 963.358 258.898 964.078 185.864C964.359 157.816 964.322 129.603 961.221 101.787C958.383 76.3448 955.558 50.8192 939.405 33.7251C907.434 -0.125026 863.906 -16.7386 823.755 -23.6273C785.564 -30.154 746.957 -27.128 708.571 -28.2316C640.094 -30.1896 555.555 -35.2508 501.872 29.4352C479.906 55.916 470.635 94.0382 448.28 119.516C428.587 141.968 404.262 147.997 380.549 159.733C356.162 171.778 332.281 188.095 314.969 214.267C299.867 237.093 291.089 265.365 283.311 293.442C275.805 320.534 270.694 352.486 254.161 372.908C240.675 389.569 222.172 395.307 205.531 404.433C187.354 414.395 169.282 431.317 157.403 452.114C137.221 487.447 145.238 536.279 126.582 572.532C109.506 605.724 79.4095 621.554 54.4922 642.262C33.1876 659.967 11.3895 684.982 3.78843 718.269C-5.71854 759.939 7.68628 791.445 40.4038 798.583C75.9237 806.332 111.964 800.019 147.697 802.078C179.282 803.894 210.705 805.045 242.522 807.074C316.848 811.862 391.464 809.928 465.551 820.252C467.772 820.561 469.995 820.881 472.219 821.213C516.015 827.74 558.284 834.688 601.093 848.507C648.388 863.779 695.393 880.547 742.384 897.315C773.794 908.535 805.764 920.2 838.274 918.675C870.783 917.151 904.655 900.104 922.858 864.735C938.183 834.961 940.383 797.207 942.049 761.357C945.728 682.549 949.179 603.728 952.402 524.893Z"
                fill="var(--nav-bg)"
              />
              <motion.path
                initial={{ x: '-100px', opacity: 1 }}
                animate={{
                  x: '200px',
                  opacity: 0,
                  transition: { yoyo: Infinity, duration: 20 },
                }}
                id="cloud-2"
                d="M689.586 157.981C689.332 155.81 691.351 151.431 693.551 151.522C694.427 151.522 695.285 151.87 696.16 151.812C698.488 151.651 699.699 149.067 700.597 146.929C701.496 144.791 703.283 142.35 705.552 142.893C708.823 143.674 710.47 149.817 714.172 145.205C716.604 142.189 716.785 137.971 719.543 135.038C721.431 133.03 724.507 131.841 727.043 132.952C730.35 134.399 731.58 139.069 735.105 139.883C739.007 140.78 741.811 136.556 743.054 133.508C744.569 129.813 745.127 125.815 746.425 122.05C747.623 118.568 750.054 113.898 754.346 114.002C757.999 114.086 759.609 117.38 761.401 119.989C763.729 123.387 768.574 135.581 774.005 134.115C776.124 133.54 777.181 131.144 779.091 130.072C782.162 128.347 786.078 130.717 787.929 133.701C789.78 136.685 790.515 140.277 792.606 143.099C793.176 143.917 793.881 144.519 794.657 144.85C797.579 145.986 800.296 142.486 803.427 142.221C804.424 142.173 805.418 142.417 806.347 142.938C807.276 143.459 808.119 144.246 808.826 145.25C810.069 147.013 810.745 149.125 811.693 151.024C811.956 151.617 812.313 152.113 812.737 152.471C814.098 153.518 816.039 152.633 817.727 152.226C821.878 151.205 826.597 153.918 827.767 158L689.586 157.981Z"
                fill="white"
              />
              <motion.path
                initial={{ x: '0px', opacity: 1 }}
                animate={{
                  x: '400px',
                  opacity: 0,
                  transition: { yoyo: Infinity, duration: 30, delay: 3 },
                }}
                id="cloud-1"
                d="M488.316 98.9224C487.999 96.4126 490.539 91.3455 493.296 91.4226C494.396 91.4582 495.473 91.832 496.573 91.7608C499.502 91.5709 501.024 88.5864 502.151 86.1063C503.278 83.6261 505.528 80.8137 508.38 81.4426C512.491 82.3445 514.564 89.4527 519.245 84.1186C522.301 80.6238 522.532 75.7406 525.995 72.3526C528.372 70.0267 532.238 68.6502 535.425 69.9318C539.581 71.611 541.129 77.0163 545.557 77.9538C550.464 78.9981 553.991 74.103 555.557 70.5785C557.454 66.3006 558.16 61.6784 559.79 57.3174C561.293 53.2827 564.317 47.8833 569.75 48.0019C574.34 48.0969 576.364 51.9061 578.619 54.9321C581.548 58.8601 587.637 72.9756 594.464 71.2787C597.126 70.6142 598.456 67.8432 600.856 66.5972C604.722 64.6036 609.643 67.3448 611.97 70.7981C614.297 74.2513 615.22 78.4106 617.855 81.674C618.569 82.6209 619.453 83.3167 620.427 83.6973C624.103 85.0145 627.521 80.962 631.455 80.6653C632.709 80.6077 633.959 80.8888 635.128 81.491C636.296 82.0932 637.357 83.0038 638.245 84.1661C639.803 86.2012 640.654 88.6102 641.867 90.8471C642.193 91.5374 642.644 92.112 643.18 92.5203C644.905 93.7366 647.331 92.7102 649.446 92.2355C654.665 91.0488 660.596 94.1995 662.072 98.9224H488.316Z"
                fill="white"
              />
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </HomeBgCloudsWrapper>
  )
}

export default HomeBgClouds
