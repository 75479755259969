import * as React from 'react'
import styled from 'styled-components'

const HomeBgWrapper = styled.div`
  position: absolute;
  bottom: 33px;
  width: calc(300px + 2.5vw);
  /* height: 100%; */

  @media (min-width: 800px) {
    display: none;
  }
`

function HomeBgMobile() {
  return (
    <HomeBgWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1606.03 1434.55"
        width="100%"
        height="100%"
      >
        <defs>
          <clipPath
            id="prefix__clip-path"
            transform="translate(-191.95 -22.01)"
          >
            <path fill="none" d="M0 0h2005v1379.79H0z" />
          </clipPath>
          <clipPath
            id="prefix__clip-path-2"
            transform="translate(-191.95 -22.01)"
          >
            <path
              className="prefix__cls-2"
              d="M1509.58 569.73c-13.07-.08-25.75-1.76-34.7-14.57-17.56-25.16-44.37-47.74-74.44-22.16-17.34 14.75-4.3 45.1-19.22 59.7-13.2 12.91-30 10.77-36.73 32.2-9.93 31.52 21.36 59.14 31.41 86.4 8.43 22.87-10.53 31.59-19.05 49.41-6.57 13.75-3.19 28.46 4 41.6 12.62 23.17 40.6 41.82 34.12 71.81-5.41 25-10.44 47.35 11.65 66.32 15.14 13 43.24 24.86 41.1 48.93-.76 8.52-6.66 15.78-8.27 24.18-2.56 13.29 6 26.23 16 35.35 22.75 20.76 42.73 40.33 59.77 66.45 7.28 11.15 36.43 36.35 24.78 51.61 21.18-27.73 43.56-54.63 62.8-83.78 15.83-24 39.68-59 23.44-88.58-5-9.18-14.44-16-17-26.19-4.64-18.35 13.39-35.37 20.09-51 10.52-24.51 2.8-53-15.74-71.38-8.77-8.7-18.29-18.28-19.57-30.57-1.41-13.42 7-25.93 10.82-38.31a191.39 191.39 0 007-35.4c.91-8.37 1.26-16.94-.71-25.12-3.75-15.57-17.77-24.23-28.14-35.28-24.71-26.29-11.72-57.22-22.52-87.78-9.7-27.42-30.75-23.73-50.89-23.84z"
            />
          </clipPath>
          <clipPath
            id="prefix__clip-path-3"
            transform="translate(-191.95 -22.01)"
          >
            <path
              className="prefix__cls-3"
              d="M1670 761.37c9.49 1.75 18.92 2.29 27.18-5.77 16.23-15.81 38.8-28.48 57.07-5.76 10.53 13.1-3.12 33.31 5.68 46 7.79 11.19 20.26 12 22.19 28.44 2.84 24.24-23.69 40-34.75 58.33-9.28 15.42 3.27 24.37 7 38.48 2.86 10.88-1.62 21.08-8.63 29.62-12.36 15.06-35.24 24.72-34.69 47.37.46 18.91 1 35.79-17.63 46.49-12.78 7.34-34.8 12-36.58 29.81-.63 6.28 2.64 12.37 2.65 18.68 0 10-8 18.19-16.54 23.42-19.37 11.91-36.57 23.34-52.55 39.93-6.82 7.08-31.46 21.33-25.12 34-11.52-23-24-45.65-34-69.46-8.16-19.58-20.61-48.32-4.74-67.49 4.93-6 12.69-9.63 16-16.65 5.91-12.67-4.81-27.5-7.52-39.76-4.24-19.23 5.31-38.82 21.3-49.6 7.57-5.1 15.8-10.72 18.43-19.46 2.88-9.54-1.49-19.78-2.55-29.29a141.24 141.24 0 01-.2-26.7c.5-6.2 1.43-12.46 4-18.12 4.87-10.78 16.24-15.11 25.3-21.69 21.56-15.66 16.42-39.89 28.48-60.56 10.84-18.56 25.6-12.96 40.22-10.26z"
            />
          </clipPath>
          <style>
            {
              '.prefix__cls-13,.prefix__cls-7,.prefix__cls-9{fill:none}.prefix__cls-2{fill:#1d7e8e}.prefix__cls-3{fill:#00a09d}.prefix__cls-7{stroke:#00a09d}.prefix__cls-13,.prefix__cls-7,.prefix__cls-9{stroke-miterlimit:10}.prefix__cls-7,.prefix__cls-9{stroke-width:5px}.prefix__cls-9{stroke:#1d7e8e}.prefix__cls-10{fill:#9d786a}.prefix__cls-11{fill:#94624f}.prefix__cls-13{stroke:#151614}.prefix__cls-14{fill:#d74034}.prefix__cls-15{fill:#ef5132}.prefix__cls-16{fill:#0b2d4e}.prefix__cls-18{fill:#66bbc4}.prefix__cls-19{fill:#66a5af}.prefix__cls-20{fill:#8ed2cc}.prefix__cls-21{fill:#061f33}.prefix__cls-22{fill:#73b8d4}.prefix__cls-23{fill:#f8a17a}.prefix__cls-24{fill:#e5785c}.prefix__cls-25{fill:#b8d1ce}.prefix__cls-27{fill:#d5eff7}'
            }
          </style>
        </defs>
        <g id="prefix__Layer_2" data-name="Layer 2">
          <g id="prefix__Layer_2-2" data-name="Layer 2">
            {/* <g
            clipPath="url(#prefix__clip-path)"
            id="prefix__Layer_3"
            data-name="Layer 3"
          >
            <circle
              cx={801.49}
              cy={705.46}
              r={705.46}
              fill="#ebf6f1"
              id="prefix__Layer_4"
              data-name="Layer 4"
            />
          </g> */}
            <path
              className="prefix__cls-2"
              d="M1509.58 569.73c-13.07-.08-25.75-1.76-34.7-14.57-17.56-25.16-44.37-47.74-74.44-22.16-17.34 14.75-4.3 45.1-19.22 59.7-13.2 12.91-30 10.77-36.73 32.2-9.93 31.52 21.36 59.14 31.41 86.4 8.43 22.87-10.53 31.59-19.05 49.41-6.57 13.75-3.19 28.46 4 41.6 12.62 23.17 40.6 41.82 34.12 71.81-5.41 25-10.44 47.35 11.65 66.32 15.14 13 43.24 24.86 41.1 48.93-.76 8.52-6.66 15.78-8.27 24.18-2.56 13.29 6 26.23 16 35.35 22.75 20.76 42.73 40.33 59.77 66.45 7.28 11.15 36.43 36.35 24.78 51.61 21.18-27.73 43.56-54.63 62.8-83.78 15.83-24 39.68-59 23.44-88.58-5-9.18-14.44-16-17-26.19-4.64-18.35 13.39-35.37 20.09-51 10.52-24.51 2.8-53-15.74-71.38-8.77-8.7-18.29-18.28-19.57-30.57-1.41-13.42 7-25.93 10.82-38.31a191.39 191.39 0 007-35.4c.91-8.37 1.26-16.94-.71-25.12-3.75-15.57-17.77-24.23-28.14-35.28-24.71-26.29-11.72-57.22-22.52-87.78-9.7-27.42-30.75-23.73-50.89-23.84z"
              transform="translate(-191.95 -22.01)"
            />
            <g clipPath="url(#prefix__clip-path-2)">
              <path
                className="prefix__cls-7"
                d="M1542.64 1198.22c9.17-105.15.75-211.13-18.68-314.68-18.53-98.78-42.85-197.91-79.72-291.55a896.21 896.21 0 00-41.41-90.71"
                transform="translate(-191.95 -22.01)"
              />
              <path
                className="prefix__cls-7"
                d="M1319.83 642.28a634.88 634.88 0 01146.44 74.24c11.49 7.86 17.9 10.26 27.48 20.36m24.55 112.65a433.44 433.44 0 00-164.37-63.45m184.01 199.08a370.58 370.58 0 00-162.32-81.47m115.56-170.28a460.9 460.9 0 0140.64-161.28m-14.16 272.81a406.91 406.91 0 0199-132.3M1541 989a152.44 152.44 0 0116.8-44.31c9.15-16 21.13-30.23 33.32-44.08q13.15-15 26.78-29.53"
                transform="translate(-191.95 -22.01)"
              />
            </g>
            <path
              className="prefix__cls-3"
              d="M1670 761.37c9.49 1.75 18.92 2.29 27.18-5.77 16.23-15.81 38.8-28.48 57.07-5.76 10.53 13.1-3.12 33.31 5.68 46 7.79 11.19 20.26 12 22.19 28.44 2.84 24.24-23.69 40-34.75 58.33-9.28 15.42 3.27 24.37 7 38.48 2.86 10.88-1.62 21.08-8.63 29.62-12.36 15.06-35.24 24.72-34.69 47.37.46 18.91 1 35.79-17.63 46.49-12.78 7.34-34.8 12-36.58 29.81-.63 6.28 2.64 12.37 2.65 18.68 0 10-8 18.19-16.54 23.42-19.37 11.91-36.57 23.34-52.55 39.93-6.82 7.08-31.46 21.33-25.12 34-11.52-23-24-45.65-34-69.46-8.16-19.58-20.61-48.32-4.74-67.49 4.93-6 12.69-9.63 16-16.65 5.91-12.67-4.81-27.5-7.52-39.76-4.24-19.23 5.31-38.82 21.3-49.6 7.57-5.1 15.8-10.72 18.43-19.46 2.88-9.54-1.49-19.78-2.55-29.29a141.24 141.24 0 01-.2-26.7c.5-6.2 1.43-12.46 4-18.12 4.87-10.78 16.24-15.11 25.3-21.69 21.56-15.66 16.42-39.89 28.48-60.56 10.84-18.56 25.6-12.96 40.22-10.26z"
              transform="translate(-191.95 -22.01)"
            />
            <g clipPath="url(#prefix__clip-path-3)">
              <path
                className="prefix__cls-9"
                d="M1559 1212.69c7.9-77.54 28.68-153.25 57.1-225.68 27.11-69.09 58.47-137.62 98.18-200.44a662.42 662.42 0 0142.59-60.07"
                transform="translate(-191.95 -22.01)"
              />
              <path
                className="prefix__cls-9"
                d="M1797.61 840.26a468.6 468.6 0 00-116.5 33.59c-9.43 4.1-14.41 5-22.76 11M1625 963.12a320.2 320.2 0 01128-23.27m-161.07 118.94a273.61 273.61 0 01129-36.63m-60.23-139.51a340.13 340.13 0 00-7.16-122.65m-27.49 199.88a300.57 300.57 0 00-53.5-109.67m16.62 210.95a112.64 112.64 0 00-6.06-34.46c-4.42-12.89-11.14-24.86-18.06-36.59q-7.48-12.68-15.34-25.12"
                transform="translate(-191.95 -22.01)"
              />
            </g>
            <path
              className="prefix__cls-10"
              d="M1274.85 1163.83h193.85l-34.01 193.99h-125.83l-34.01-193.99z"
            />
            <path
              className="prefix__cls-11"
              transform="rotate(180 1467.75 1147.6)"
              d="M1443.07 1131.38h241.32v54.45h-241.32z"
            />
            <path
              fill="#231f20"
              opacity={0.1}
              d="M1464.19 1189.55h-184.83l-4.51-25.72h193.85l-4.51 25.72z"
            />
            <path
              className="prefix__cls-11"
              d="M46.98 1240.17h268.77v24.51H46.98zm20.56-393.04H299.3v39.76H67.54z"
            />
            <path className="prefix__cls-10" d="M0 820.02h366.83v27.11H0z" />
            <path
              className="prefix__cls-11"
              d="M356.45 1358.54h-29.37l-56.92-471.66h29.36l56.93 471.66zm-346.06 0h29.36l56.93-471.66H67.31l-56.92 471.66z"
            />
            <path
              className="prefix__cls-10"
              d="M173.03 847.13h20.78v511.41h-20.78z"
            />
            <path className="prefix__cls-13" d="M201.8 653.84l.17-13.89" />
            <path
              className="prefix__cls-14"
              d="M175.48 655.08h52.99v151.34h-52.99z"
            />
            <path
              className="prefix__cls-15"
              d="M228.47 655.09h-52.99l4.36-4.97h44.1l4.53 4.97z"
            />
            <path className="prefix__cls-13" d="M270.18 679.14l.17-13.89" />
            <path
              className="prefix__cls-14"
              d="M243.85 680.38h52.99v126.04h-52.99z"
            />
            <path
              className="prefix__cls-15"
              d="M296.85 680.38h-53l4.36-4.97h44.1l4.54 4.97z"
            />
            <path
              className="prefix__cls-16"
              d="M157.7 806.43h155.86v8.27a4.83 4.83 0 01-4.83 4.83h-146.2a4.83 4.83 0 01-4.83-4.83v-8.27z"
            />
            <path
              className="prefix__cls-3"
              d="M50.87 675.9h100.51v118a26 26 0 01-26 26H76.87a26 26 0 01-26-26v-118z"
            />
            <path
              className="prefix__cls-3"
              d="M350.35 730.47c-24.1 0-43.64 19.82-43.64 44.28S326.25 819 350.35 819 394 799.2 394 774.75s-19.54-44.28-43.65-44.28zm0 72.77a28.49 28.49 0 1128.08-28.49 28.29 28.29 0 01-28.08 28.49z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              d="M257.86 787.23a5.46 5.46 0 01-5.44-5.44v-70.06a5.45 5.45 0 015.44-5.43 5.45 5.45 0 015.43 5.43v70.06a5.46 5.46 0 01-5.43 5.44z"
              transform="translate(-191.95 -22.01)"
              opacity={0.2}
              fill="#fff"
            />
            <path
              className="prefix__cls-2"
              d="M320.12 697.91v118.51a25.44 25.44 0 01-25.45 25.45h23.21a25.45 25.45 0 0025.46-25.45V697.91z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-18"
              d="M806.14 1200.61h363.16c100.74 0 182-82.44 180.53-183.18l.22-504.78a180.56 180.56 0 00-180.54-177.92h-356A180.55 180.55 0 00633 512.65l-7.36 504.78c-1.5 100.74 79.76 183.18 180.5 183.18z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-19"
              d="M806.14 1203.13h363.16c100.74 0 182-82.45 180.53-183.18 0 0 1.35-287.84.15-354.74-163.71 226.16-555 228.44-719.24 2.54-1.42 87.85-5.13 352.2-5.13 352.2-1.47 100.73 79.79 183.18 180.53 183.18z"
              transform="translate(-191.95 -22.01)"
            />
            <rect
              className="prefix__cls-18"
              x={713.82}
              y={898.94}
              width={552.85}
              height={140.11}
              rx={7.5}
              transform="rotate(180 894.27 957.995)"
            />
            <path
              className="prefix__cls-20"
              d="M733.38 898.94h515a18.94 18.94 0 0118.93 18.94H714.45a18.94 18.94 0 0118.93-18.94z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-19"
              d="M732.75 1039.05h515a18.94 18.94 0 0018.93-18.93H713.82a18.94 18.94 0 0018.93 18.93z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-11"
              transform="rotate(180 897.425 1242.625)"
              d="M593.91 1218.29h798.98v70.68H593.91z"
            />
            <path
              className="prefix__cls-11"
              d="M1305 1305.34a45.38 45.38 0 1045.38-45.39 45.38 45.38 0 00-45.38 45.39z"
              transform="translate(-191.95 -22.01)"
            />
            <circle
              className="prefix__cls-11"
              cx={1350.41}
              cy={1350.72}
              r={27.85}
              transform="rotate(-67.09 1237.873 1484.478)"
            />
            <circle
              className="prefix__cls-11"
              cx={634.24}
              cy={1306.56}
              r={45.38}
              transform="rotate(-13.28 443.877 2120.237)"
            />
            <path
              className="prefix__cls-11"
              d="M606.39 1351.94a27.86 27.86 0 1027.85-27.86 27.85 27.85 0 00-27.85 27.86z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-3"
              d="M647.5 675c2.08 9.45 42 185.64 43.91 199.49s-.64 57.5-.55 49.73-.61 16.46 14.62 14.06c7.61-1.19 65.57-19.9 124.42-29.18 59-9.31 118.92-9.2 124.71-10.24 11.57-2.08 15.68-8.39 19.58-26.31S952 782.17 947.93 758.72s-6.15-111.49-8.84-123c-3.58-15.23-18.24-20-30.79-18.31-7.17 1-80.91 16.45-138.48 24.26-56.8 7.7-97.45 7.74-103.4 8.54-11.98 1.62-21 15.32-18.92 24.79z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-2"
              d="M648.53 674.85c2.08 9.45 42 185.64 43.91 199.49s-.63 57.5-.55 49.73-.61 16.46 14.63 14.06c7.6-1.19 65.56-19.9 124.42-29.18s118.92-9.2 124.71-10.24c11.57-2.08 15.68-8.39 19.57-26.31 1.77-8.14-2.64-27.53-8.37-48.47-123.74 63.19-283.28 48.11-285-174.62-7.63.34-12.61.51-14.41.75-11.97 1.63-20.99 15.34-18.91 24.79z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-18"
              d="M576.87 767.67h82a54.91 54.91 0 0154.91 54.91v219h552.85v-219a54.91 54.91 0 0154.91-54.91h82a54.92 54.92 0 0154.91 54.91v219l-12.62 159H534.58l-12.62-159v-219a54.91 54.91 0 0154.91-54.91z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-19"
              d="M583.81 1248.58h814.13a48 48 0 0048-48H535.85a48 48 0 0047.96 48z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-20"
              d="M1348.78 512.65c-1.44-98.68-98.14-225.88-296.65-225.88H923.31c-194 0-290.34 126.1-290.34 225.88 188.75-49.32 133 77.05 357.27 77.05s189.76-111.92 358.54-77.05z"
              transform="translate(-191.95 -22.01)"
            />
            <circle
              className="prefix__cls-18"
              cx={1197.15}
              cy={809.01}
              r={122.85}
            />
            <circle
              className="prefix__cls-20"
              cx={1389.11}
              cy={831.01}
              r={61.43}
              transform="rotate(-64.84 1275.888 971.124)"
            />
            <circle
              className="prefix__cls-18"
              cx={399.43}
              cy={809.01}
              r={122.85}
            />
            <circle
              className="prefix__cls-20"
              cx={591.38}
              cy={831.01}
              r={61.43}
              transform="rotate(-45 468.842 1051.71)"
            />
            <path
              className="prefix__cls-19"
              d="M969.4 438.23a21.48 21.48 0 1021.48-21.47 21.48 21.48 0 00-21.48 21.47z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1249.63 1346.91c-30.18-1.43-60.26 3.42-90.09 8.26-9.42 1.52-19.39 6.75-12.29 18 3.89 6.18 12 8 19.22 8.82 31.16 3.57 63.17-.38 94.49-.33"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-21"
              d="M1471.64 1305.07c4.58 28-8.55 68.43-39.1 76.6-12.32 3.3-27.15 2.13-39.87 2q-23.22-.13-46.43-.33l-91.21-.65c-20-.15-41.35-.66-58.07-11.7-19.88-13.12-29.78-37.38-16.56-58.26 11.35-17.94 32.58-28.93 52.83-33.29 33.93-7.31 69.17 2.38 102.89-3.7a99 99 0 0016.22-4.32c30.45-11 60.7-26.25 91.78-8.23 14.88 8.7 24.73 24.86 27.52 41.88z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1219.8 1244.81c-5.08-5.37-8.2-6.42-11.27 1.21-3.54 8.81-5.93 18.41-5.82 28a83.41 83.41 0 00-34.91 16.63c-7.82-5.56-17.11-9.07-26.42-11.16-8-1.81-8.93 1.35-7.39 8.58 1.9 8.92 4.94 17.53 7.88 26.15 1.84 5.4 3.51 10.85 5.39 16.22a38.72 38.72 0 007.17 14.9c8.88 11 26.55 17.45 40.24 19.26 15.5 2 30.18-1.91 40.62-13.69 9.37-10.57 15.76-25 18.19-38.83a46.88 46.88 0 00-2-23.92c-3.11-8.55-9.25-14.77-14.6-21.92-5.49-7.34-10.81-14.79-17.08-21.43zm228.98 38.02c29.18 27.78 26.12 59.8 10 88.12-17.88 31.41-47.32 52.68-80.16 66.52-38.78 16.34-83.16 24.7-124.7 14.9-8.34-2-17.47-6.07-19.93-14.29-7.28-24.31 30.65-23.88 44.17-25 27.8-2.37 57.25-2.66 84.29-11.7 29.66-9.92 50.79-31.08 65.63-58.16"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1469.93 1327.6a59.61 59.61 0 00-12.68-36.3c-20.65-26-60.84-26.77-88.51-12.7-31.74 16.15-36.77 58.9-24.34 89 .57 1.38-11.12 1-12.1 1-8.39.6-25.76-1-29.11 9.28-2.24 6.89 2.36 16.2 9.28 18.44a29.35 29.35 0 009 .93c21.75 0 43.48 0 65.23.08 17 0 29.87-3.12 40.07-17.64 5.31-7.56 9.33-16 15-23.3"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-21"
              d="M1216.63 1337.18c1.23 3.17-.77 6.91-4.46 8.33s-7.68 0-8.91-3.16.77-6.91 4.46-8.34 7.68-.01 8.91 3.17z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-22"
              d="M1247.25 1279a58.17 58.17 0 003.56 5.38 17.46 17.46 0 011.63 2.91 20.91 20.91 0 011 3.21c.51 2.15.81 4.32 1.11 6.45s.54 4.34.65 6.53a61.93 61.93 0 01-.76 13.18 65 65 0 01-9.42 24.59 59.89 59.89 0 01-8.27 10.29 55 55 0 01-10.32 8.17 25.67 25.67 0 01-6.07 2.66 14.28 14.28 0 01-6.52.35c4.37-.05 8.2-2.11 11.5-4.64a53.14 53.14 0 004.77-4.12c.75-.74 1.52-1.45 2.24-2.23s1.43-1.52 2.12-2.32a68.24 68.24 0 007.33-10.16 69.93 69.93 0 005.46-11.25 72.48 72.48 0 004.83-24.48c.06-2.11 0-4.16-.14-6.33s-.18-4.26-.43-6.34a18.25 18.25 0 00-.55-3 20 20 0 00-1.14-2.9 39.63 39.63 0 01-2.58-5.95z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1118.19 921.63a232.45 232.45 0 01-25.54 10.37 537.24 537.24 0 01-119.2 27.08c-42.41 4.66-87.38 3.75-124.93-16.49-62.31-33.59-54.64-92.73-54.64-153a16.86 16.86 0 0116.86-16.86H1090c15 0 31.7-2.09 46.58 0 8.41 1.18 15.52 1.82 19.29 11.36 2.65 6.68 1 21.54 2.2 29.16 4.43 28.37 10.8 64.41-10.43 87.32-8.27 8.99-18.47 15.66-29.45 21.06z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M836.05 729.48c-1.23-2.77-2.46-5.6-3.77-8.52a420.55 420.55 0 01-29.6-95.21c-2.18-11.81-4.39-24.73-13.41-32.66-12.49-11-36.82-9.55-52.29-7.48-7.22 1-14.78 3.46-19.14 9.3-7.24 9.68-6.51 31.53-6.8 43.19-1.73 70.9 54.32 129.5 99.96 177.34 8.79 9.21 18.33 18.52 30.5 22.25s27.63-.15 33-11.71c3.91-8.49 1.51-18.45-1.58-27.27-5.32-15.16-13.33-29.05-22.44-42.23-6.22-8.9-10.27-17.6-14.43-27zm275.5 0c1.23-2.77 2.46-5.6 3.77-8.52a420 420 0 0029.6-95.21c2.18-11.81 4.39-24.73 13.42-32.66 12.48-11 36.81-9.55 52.28-7.48 7.23 1 14.78 3.46 19.14 9.3 7.24 9.68 6.52 31.53 6.8 43.19 1.73 70.89-54.31 129.5-100 177.34-8.79 9.21-18.32 18.52-30.5 22.25s-27.63-.15-32.95-11.71c-3.91-8.49-1.51-18.45 1.58-27.27 5.32-15.16 13.33-29.05 22.44-42.23 6.21-8.9 10.26-17.6 14.42-27z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M751.58 501c-4.35 10.95-8.53 22-12.64 33-14.06 37.79-47.51 101.89-9.79 136.72 4.28 4 14.56 3.73 19.86 2.68 15.18-3 22.82-17.14 30.25-29.17 22.31-36.11 38.49-75.67 60-112.28 15.52-26.42 23.38-57.93 30.83-87.37 4.93-19.48 11.27-47.37-5.16-63.51a32 32 0 00-25.71-8.81c-5.61.65-10.93 2.79-16 5.28a58.41 58.41 0 00-12.24 7.7c-9.12 7.78-13.53 19-19 29.39-6.39 12.19-12.93 24.26-18.9 36.66-7.79 16.31-14.85 32.93-21.5 49.71z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-14"
              d="M768.55 441.37c20.84-38.24 48-72.63 89.75-86.66 12-4 26.76-5 35.73 3.88 5.63 5.57 7.66 14 7.19 21.87-.69 11.46-5.87 22.32-8.52 33.41a368.71 368.71 0 01-31.81 85.26c-3 5.81-25.29 68.8-23.49 70.36-14.68-12.75-35.6-28.63-53.16-37-4.66-2.22-47.21-24.12-48.44-21.35 9.86-22.22 20.2-46.69 32.75-69.77z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M1200.85 501c4.34 10.95 8.52 22 12.63 33 14.06 37.79 47.51 101.89 9.79 136.72-4.28 4-14.56 3.73-19.86 2.68-15.18-3-22.82-17.14-30.25-29.17-22.31-36.11-38.49-75.67-60-112.28-15.53-26.42-23.38-57.93-30.83-87.37-4.94-19.48-11.28-47.37 5.15-63.51a32 32 0 0125.72-8.81c5.6.65 10.92 2.79 16 5.28a58.34 58.34 0 0112.23 7.7c9.12 7.78 13.53 19 19 29.39 6.39 12.19 12.93 24.26 18.9 36.66 7.8 16.31 14.86 32.93 21.52 49.71z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-14"
              d="M1183.69 441.37c-20.85-38.24-48-72.63-89.76-86.66-12-4-26.75-5-35.73 3.88-5.62 5.57-7.66 14-7.19 21.87.69 11.46 5.88 22.32 8.52 33.41a369.17 369.17 0 0031.81 85.26c3 5.81 25.29 68.8 23.5 70.36 14.67-12.75 35.59-28.63 53.15-37 4.66-2.22 47.21-24.12 48.44-21.35-9.86-22.22-20.16-46.69-32.74-69.77z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-15"
              d="M1124.38 735c-.27-1.14-.54-2.28-.8-3.42-9.86-42.68-11.31-86.73-10.07-130.35 1.21-42.77 10.73-84.85 12-127.65 1.42-49.35-7.17-119.45-68.93-125.16-25.54-2.35-51.23-5.11-76.95-5.41V343h-5.5c-25.72.3-51.41 3.06-77 5.41-61.76 5.71-70.35 75.81-68.93 125.16 1.24 42.8 10.76 84.88 12 127.65 1.24 43.62-.21 87.67-10.06 130.35l-.81 3.42c-1.94 8-5.07 16.15-3.6 24.27 3.17 17.55 45.42 26.06 59.66 29.84 26.55 7 54 10.18 81.31 12.2l7.36.53v.38l2.8-.19 2.81.19v-.38l7.35-.53c27.34-2 54.76-5.16 81.31-12.2 14.25-3.78 56.5-12.29 59.66-29.84 1.46-8.11-1.67-16.26-3.61-24.26z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M937.11 389.63c38.14 25.17 112.37 5.65 91.33-44.28-2.55-6.06-5.65-11.92-7.35-18.24-2.83-10.49-2.55-21.29-2.26-32 .34-12.46.44-24.94 1.13-37.38-14-2.61-25.25-3.91-40.06-3.37-12.25.45-24.49-.69-36.73-.05a4.73 4.73 0 00-2.76.72 3.24 3.24 0 00-.91 2.34c-1.64 16.81 0 33.84-1.31 50.69-.72 9.43-2 20.21-6.32 29-6.62 13.45-16.47 25.94-7 40.54a42.09 42.09 0 0012.24 12.03z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-24"
              d="M1020.53 324.85c-2.2-9.75-1.63-20-1.36-29.84.34-12.46.44-24.94 1.12-37.38-13.94-2.61-25.25-3.91-40-3.37-12.25.45-24.49-.69-36.74 0a4.72 4.72 0 00-2.75.72 3.24 3.24 0 00-.91 2.34c-1.64 16.81 0 33.84-1.31 50.69a109.89 109.89 0 01-2.06 14.41c1.92 1.49 3.88 2.92 5.89 4.23 15.12 9.89 35.51 13.18 53.6 8.64a83.62 83.62 0 0024.52-10.44z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-25"
              d="M579.75 553.29l-3.48 8.24h414.74l-4.16-9.26-407.1 1.02z"
            />
            <path
              className="prefix__cls-25"
              d="M1154.35 845.62H796.69l-25.62-266.27a8.07 8.07 0 017.95-8.91h393a8.07 8.07 0 018 8.91z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              d="M1157.16 853.15H793.88l-26-266.27a8.11 8.11 0 018.08-8.91h399.14a8.11 8.11 0 018.08 8.91z"
              transform="translate(-191.95 -22.01)"
              fill="#fff"
            />
            <path
              className="prefix__cls-25"
              d="M601.93 831.14h363.05v16.16H601.93z"
            />
            <path
              className="prefix__cls-25"
              d="M1000.71 715.56a25.19 25.19 0 11-25.19-25.19 25.18 25.18 0 0125.19 25.19z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M955.73 911.47c-13.17 4.73-26.39 9.3-39.62 13.52-13.54 4.31-91.16 16.79-75.76 46.7 3 5.87 10.35 7.79 16.84 9 37.35 6.9 75.37 3.79 113.09-.74"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-27"
              d="M899.52 985.79c-16 4.62-33.28 6.93-48.33 10.38-6.78 1.55-13.57 3.11-20.44 4.21-10.92 1.74-22 2.33-33 2.91l-24.06 1.28c-4.36.23-8.93.42-12.91-1.4-4.75-2.18-7.8-6.82-10.6-11.24-5.55-8.76-12.13-17-16.77-26.32-3-6.1-6.19-12.06-2.86-18.76a31.61 31.61 0 016.87-8.51 58.6 58.6 0 0111.1-8.64c13.42-7.65 27.67-4.29 41.91-1.39 15.07 3.06 30.14 6.56 45.54 7.47 16.58 1 33.11-1.08 49.57-2.79"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-14"
              d="M843.27 1006.32l7.62-.5c6-.37 13.45-1.06 18.08-5.32a6.63 6.63 0 001.75-2.33c.9-2.25-.34-4.91-2.28-6.36a14.84 14.84 0 00-6.81-2.32c2.78.42 7-9.93 7.47-11.84a52.32 52.32 0 001.42-16.65c-.48-5.07-1.78-9.93-5.12-13.83-1.71-2-7.67-8.09-10.77-7.19 2.69-.78 4-4.14 3.24-6.83s-3.28-4.6-5.94-5.48a25.28 25.28 0 00-8.33-.83c-14.71.21-29.65 1.23-44.2-1.63a135.49 135.49 0 00-38.16-2.21 88.15 88.15 0 00-28.51 7.39 40.08 40.08 0 00-7 4c-29.5 21.1-7.38 58.19 18.41 70.36 19.08 9 38.63 6.56 58.85 4.73 13.45-1.2 26.8-2.26 40.28-3.16z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1044.47 270.63c-11.56 13.95-27.76 23.14-43.8 31.57-3.94 2.07-7.94 4.13-12.27 5.14-13.28 3.1-26.69-4.1-37.77-12a218.87 218.87 0 01-41.15-38.53c-12.05-14.64-18.79-30.63-26.65-47.85s-9.65-34.77-8-53.72c1.71-19.46-9.17-36.85-4.25-56.53a56.23 56.23 0 0121.12-31.43c5.7-4.17 12.31-7.28 19.32-8 15.68-1.7 29 9 44.52 9.69s30.69-5 45.71-8.38c13.13-3 27.51-4.63 40.54-.17 14.5 5 21.48 16 29.5 28.89 6.85 11 17.17 19.21 25.41 29 11 13.06 14.54 29.67 11 46.67a144.56 144.56 0 01-15.87 41.87c-6.37 11.09-13.3 22.78-20.79 33.29-5.71 8-12 13.91-18.64 20.89-2.84 2.89-5.24 6.34-7.93 9.6z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M1058.67 203.87c-.25-16-2.61-31.09-6.84-43.63-18.27-54.1-86.1-66.83-126.49-27.47-45 43.9-26.59 147.6 21.66 183.61a53.7 53.7 0 0045.84 8.56c46.92-13.09 66.62-71.19 65.83-121.07z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-24"
              d="M1069.75 160.06C1049.2 106 972.65 93.34 927 132.76c-10.67 9.21-22 23.72-27 37-5.3 14.22-3.91 29.94-2.45 45 1.14-12.47 7-23.68 15.17-33a110 110 0 0113.31-12.58c1.72-1.39 14.66-13.15 13.42-14.76 4.6 6 9.17 12 16.26 15 8.87 3.76 18.09 5.94 27.46 8 16.57 3.6 29.55 8.26 41.57 20.66 11.11 11.46 19.16 25.71 24.5 40.69 1.69 4.73 4.57 12 10.76 9.89 1.47-.5 14.21-10 14.34-10.66a165.22 165.22 0 003.05-34.31c-.24-15.97-2.87-31.1-7.64-43.63z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M1033.12 193.07c4.12 6.74 6.65 14.33 9.14 21.83 3.94 11.87 7 24.63 12.11 36 17.21-10.4 28.56-34.9 36.4-52.62 7-15.72 8.91-28.22 3-44.71-3.81-10.66-11.11-20.13-19.78-28.25-25-23.45-62.54-35.39-98.73-33-12.72.83-23.08 5.31-35 8.77-11 3.18-19.35 5.26-27.4 14.28-7.16 8-11.76 18-15.36 28.15-5.88 16.53-9.2 33.57-7.91 51.13.83 11.22.9 22.34 12 28.45l-.58-20.05a47.88 47.88 0 01.36-9.58 47.06 47.06 0 012.43-8.2c3-8.19 5.69-14.76 11.31-21.51 7.35-8.82 17.06-16.81 19.12-28.12 17.41 16.92 38 27.52 60.83 35.4 14.51 5 29.26 7.87 37.87 21.73z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M1059 205.3c20.72-11.6 27.42 17.18 24 31.57a45.85 45.85 0 01-7.34 16.66c-4.89 6.61-19 17.65-27.19 10.55-3.74-3.23-4.17-8.76-4.34-13.7-.46-13.22.87-37.22 14.87-45.08zm-149.72 0c-20.72-11.6-27.42 17.18-24 31.57a45.83 45.83 0 007.33 16.66c4.89 6.61 19 17.65 27.19 10.55 3.74-3.23 4.17-8.76 4.34-13.7.47-13.22-.82-37.22-14.86-45.08z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              d="M687.8 136.42c-.18-8.45-5.79-15.63-10.07-22.91a61.59 61.59 0 01-7.69-38 50.29 50.29 0 0124.15-37.3 42.06 42.06 0 0132.65-4.51c10.21 2.91 18.5 10.72 28.41 13.44 22.21 6.07 45-12 66.08-16.34 20.91-4.33 43.27 2.71 53.73 23.74 4.13 8.29 5.94 17.9 11.34 25.34 9 12.42 26.32 16.53 33.48 30.22 11.7 22.37-11.55 55.06-23.38 72.44"
              stroke="#0b2d4e"
              strokeWidth={3}
              strokeMiterlimit={10}
              fill="none"
            />
            <path
              className="prefix__cls-22"
              d="M932.23 92.39c17.23 1.11 34-2.61 50.85-6a172.34 172.34 0 0125.75-2.62c4.31-.12 8.64-.1 12.95.07a81.57 81.57 0 0113 1.29A36.49 36.49 0 011057 98.87c2.69 3.82 3.45 8.53 3.74 12.83s.18 8.6 1.07 12.63 2.89 7.64 5.45 11 5.42 6.71 7.39 10.67a33 33 0 013 12.67 90.68 90.68 0 01-.47 12.88 111.78 111.78 0 00-.29-12.82 31.71 31.71 0 00-3.45-12.08c-2-3.69-4.87-6.84-7.57-10.22s-5.23-7.24-6.17-11.65c-1.1-4.34-1.08-8.75-1.39-12.94s-1.08-8.29-3.43-11.54a23.44 23.44 0 00-4.14-4.46 34.87 34.87 0 00-5-3.65 36.78 36.78 0 00-11.54-4.35 78.38 78.38 0 00-12.55-1.36q-6.37-.31-12.77-.2a204.8 204.8 0 00-25.45 2.18c-8.4 1.33-16.89 3.09-25.45 4a113.79 113.79 0 01-25.75-.07z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M939.81 988.1c17.61-2.12 70.37-10.15 87.74-12 87.2-9.4 173.48-23.66 254.26-59.59 18.62-8.32 38.27-22 48.72-39.8 27.4-46.91-19.11-95.1-67.91-84.16-81.5 18.28-148.18 52.22-226.91 80.14-13.14 4.66-36.77 14.64-50.09 19.57-11.44 4.23-49.81 15.69-61.33 19.82"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-23"
              d="M972.54 1159.63c4.26 12.89 8.66 25.72 13.35 38.35 4.79 12.92 41.63 79.7 9.58 85.8-6.3 1.2-12.14-3.26-16.94-7.5-27.59-24.41-47.93-55.32-67-86.86"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-27"
              d="M949.33 1247c6.07 15 14.59 29.57 20.95 43.13 2.87 6.11 5.73 12.23 9 18.14 5.19 9.38 11.35 18.18 17.5 27l13.4 19.14c2.43 3.48 5 7.09 8.78 9 4.51 2.33 9.88 1.89 14.92 1.38 10-1 20.25-.89 30.14-2.92 6.47-1.32 12.92-2.47 16-9a30.66 30.66 0 002.39-10.34 56.58 56.58 0 000-13.64c-2.17-14.84-13.25-23.71-24-32.86-11.34-9.69-23-19.11-32.92-30.33-10.66-12.09-19-26-27.48-39.55"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-14"
              d="M967.26 1302.24l-4.18-6.12c-3.29-4.8-7.22-10.91-6.73-17a6.31 6.31 0 01.73-2.73c1.18-2 3.95-2.67 6.22-2.06a14.51 14.51 0 015.84 3.82c-2-1.86 3.42-11.27 4.59-12.78a50.64 50.64 0 0111.9-11c4.16-2.66 8.65-4.58 13.63-4.35 2.55.11 10.76 1 11.93 3.93-1-2.52.74-5.57 3.27-6.55a8.69 8.69 0 017.74 1.26 24.66 24.66 0 015.62 5.86c8.63 11.38 16.78 23.4 27.65 32.81a131.32 131.32 0 0124.49 27.84 85.58 85.58 0 0111.39 26.2 39.55 39.55 0 011.12 7.79c1.5 35.15-40.06 40.41-64.77 28-18.27-9.21-28.09-25.6-38.78-42.14-7.13-11.11-14.29-21.94-21.66-32.78z"
              transform="translate(-191.95 -22.01)"
            />
            <path
              className="prefix__cls-16"
              d="M923.49 1217.58c-8.9-14.72-34.29-59.83-43.24-74.23-44.92-72.24-85.58-146.7-106.39-229.89-4.76-19.2-6.09-42.37 1.3-61 19.48-49 84.1-42.22 104.89 1.61 34.73 73.2 48.64 144.43 74.35 221.27 4.29 12.82 10.78 36.85 15 50 3.61 11.27 17.78 47.44 21.5 58.71"
              transform="translate(-191.95 -22.01)"
            />
          </g>
        </g>
      </svg>
    </HomeBgWrapper>
  )
}

export default HomeBgMobile
